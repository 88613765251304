import React, { useEffect } from 'react'
import Layouts from '../../Layouts';
import { secureStorage } from '../../../functions/isAuth';
import { useNavigate } from 'react-router-dom';

function ReportsPage() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = secureStorage.getItem('access_token');
        if (!token) {
          navigate('/');
        }
    }, []);
    
    return (
        <Layouts>
            <div>
                <div className='flex items-center justify-between mb-8'>
                    <h1 className='text-2xl font-bold'>Reports</h1>
                </div>
                <div className='flex '>
                   
                </div>

            </div>
        </Layouts>
    )
}

export default ReportsPage