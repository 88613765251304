import React, { useEffect, useState } from 'react';
import Layouts from "../../Layouts"
import { FiShoppingBag } from "react-icons/fi";
import { IoIosTrendingUp } from "react-icons/io";
import { IoIosTrendingDown } from "react-icons/io";
import { FaDollarSign } from "react-icons/fa";
import { IoDocumentOutline } from "react-icons/io5";
import { LuUsers } from "react-icons/lu";
import './style.css';
import { useNavigate } from 'react-router-dom';
import LineChart from "../../Shared/Charts/LineChart"
import BarChart from '../../Shared/Charts/BarChart';
import PieChart from '../../Shared/Charts/PieChart';
import DoughNutChart from '../../Shared/Charts/DoughNutChart';
import { BiArrowToRight } from 'react-icons/bi';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { secureStorage } from '../../../functions/isAuth';
import Table from '../../Shared/Table';
import { orderData } from '../../../data/orderData';

const Dashboard = () => {
  const [currMonth, setCurrMonth] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const token = secureStorage.getItem('access_token');
    if (!token) {
      navigate('/');
    }
    else {
      setCurrMonth(months[new Date().getMonth()]);
    }
  }, []);


  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const handleMonthChange = (value) => {
    const month = months.find((month) => month === value);
    setCurrMonth(month);
  }

  return (
    <Layouts>
      <div className='md:mx-12'>
        <div className='w-full flex justify-between bg-borderColor py-5 px-3.5 rounded-xl'>
          <h1 className='md:text-2xl w-[inherit] text-xl font-bold text-textGray'>DashBoard</h1>
          <Select
            defaultValue={currMonth}
            onChange={handleMonthChange}
            className='md:w-[10%] w-[50%] overflow-x-hidden'
          >
            {months.map((month) => (
              <Option value={month}>{month}</Option>
            ))}
          </Select>
        </div>
        <div className='w-full flex-wrap flex md:gap-8 md:justify-around md:items-center'>
          <div className='md:w-[25%] w-screen items flex justify-evenly items-center bg-white'>
            <div className='icon-wrapper'>
              <FiShoppingBag className='icons' />
            </div>
            <div className=' flex flex-col items-center'>
              <p>Total Orders</p>
              <p className='font-medium'>10,451</p>
            </div>
            <div className='flex gap-3 items-center'>
              <IoIosTrendingUp className='text-green-500 text-2xl' />
              <p className='percentage'>7.56%</p>
            </div>
          </div>
          <div className='md:w-[25%] w-screen items flex justify-evenly items-center bg-white'>
            <div className='icon-wrapper'>
              <FaDollarSign className='icons' />
            </div>
            <div className='flex flex-col items-center'>
              <p>Total Revenue</p>
              <p className='font-medium'>₹ 6,37,802</p>
            </div>
            <div className='flex gap-3 items-center'>
              <IoIosTrendingUp className='text-green-500 text-2xl' />
              <p className='percentage'>1.56%</p>
            </div>
          </div>
        </div>
        <div className='md:flex grid grid-cols-1 w-full md:gap-16 items-center justify-between py-12'>
          <div className='md:w-2/4 w-screen py-4'>
            <p className='md:text-2xl text-xl w-max font-semibold text-textGray underline'>Quarterly Sales Analysis</p>
            <LineChart />
          </div>
          <div className='w-2/5'>
            <PieChart />
          </div>
        </div>
        <div className='md:flex w-full grid grid-cols-1 py-12 items-center justify-between'>
          <div className='w-2/5'>
            <DoughNutChart />
          </div>
          <div className='w-2/4'>
            <BarChart />
          </div>
        </div>
        <div className='md:flex w-full grid grid-cols-1 py-12'>
          <div className='md:w-full w-screen py-4'>
            <p className='md:text-2xl text-xl w-max font-semibold text-textGray md:py-6 py-2'>Recent Orders :</p>
            <Table data={orderData} />
          </div>
        </div>
      </div>
    </Layouts>
  )
}

export default Dashboard;
