import React, { useState } from 'react';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import Pagination from '../../Shared/Pagination';
import userImage from '../../../Assets/Images/dummy-profile.png'


function Table({ data, page, handleModal }) {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(data?.length / itemsPerPage);

    const handleEditClick = (item) => {
        navigate('/updateUser',{state:{user:item}});
    };



    return (
        <div>
            <div className={`bg-white ${data? 'flex-1':'w-full'} rounded-lg flex justify-center`}>
                <table className={`w-full m-0`}>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th colSpan={3}>Action</th>
                        </tr>
                        <tr>
                            <td className='p-2' colSpan={12}></td>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {
                            currentItems?.map((item, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <div className='flex items-center gap-3'>
                                            {/* <img src={userImage} alt="product" width={40} height={40} className="rounded-[50%]" /> */}
                                            <span>{`${item.firstName} ${item.lastName}`}</span>
                                        </div>
                                    </td>
                                    <td>{item.email}</td>
                                    <td>{item.mobile}</td>
                                    <td>
                                        <div className='flex items-center md:gap-6 gap-3'>
                                            <MdOutlineRemoveRedEye className='text-primaryColor md:text-lg text-sm' onClick={() => handleModal('view', item)} />
                                            <BiEditAlt className='text-successColor md:text-lg text-sm' onClick={() => handleEditClick(item)} />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end mt-[8rem]">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                    containerClassName="mb-4"
                    buttonClassName="border border-gray-300 md:px-3 px-2 rounded-full md:py-1 py-0.5 transition duration-300 ease-in-out focus:outline-none hover:bg-gray-200"
                    activeButtonClassName="bg-blue-500 rounded-full text-white"
                />
            </div>
        </div>
    );
}

export default Table;
