import axios from "axios";
import {Base64} from 'js-base64';
import CryptoJS from "crypto-js";
import SecureStorage from "secure-web-storage";
const SECRET_KEY = 'secureMyData';



export const secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  }
});

export const getToken = async(userData) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const {userId,password} = userData;
    const encodedPassword = Base64.encode(password);
    try{
        const response = await axios.get(`${baseUrl}/oauth/token`,{
            params:{
                userId,password:encodedPassword
            }
        });
        console.log(response.data.access_token);
        secureStorage.setItem('access_token',response.data.access_token);
        return await isAuth({userId,token:response.data.access_token});

    }catch(e){
        return e;
    }
}
export const isAuth = async(userCredentials) => {
    const {userId,token} = userCredentials;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    try{
        const response = await axios.get(`${baseUrl}/oauth/tokenValidate`,{
            params:{
                userId,token
            }
        });
        return response;

    }catch(e){
        return e;
    }
}