import React, { useEffect, useRef, useState } from 'react';
import { Form, Select, InputNumber } from "antd";
import { deliveryDetails, saveDeliveryCharge, statesList, updateDeliveryCharge } from '../../../../functions/products';
import Layouts from '../../../Layouts';
import { secureStorage } from '../../../../functions/isAuth';
import { useNavigate } from 'react-router-dom';

function Index() {
    const [deliveryData, setDeliveryData] = useState([]);
    const [selectedState, setSelectedState] = useState({ stateId: 'ALL' });
    const [states, setStates] = useState();
    const [deliveryCharge, setDeliveryCharge] = useState();
    const [deliveryCutOffAmount, setDeliveryCutOffAmount] = useState();
    const [deliveryTimePeriod, setDeliveryTimePeriod] = useState();
    const [form] = Form.useForm();
    const formRef = useRef();
    const navigate = useNavigate();


    useEffect(() => {
        const token = secureStorage.getItem('access_token');
        if (!token) {
            navigate('/');
        }
        else {
            getDeliveryDetails();
            getAllStates();
        }
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            deliveryCharge: deliveryData?.deliveryCharge,
            deliverytimeperiod: deliveryData?.deliveryTimePeriod,
            deliverycutoffamount: deliveryData?.deliveryCutOffAmount
        });
    }, [deliveryData]);



    console.log(deliveryData);

    const getDeliveryDetails = async () => {
        const response = await deliveryDetails(selectedState);
        console.log(response);
        setDeliveryData(response.data[0]);
    }

    const getAllStates = async () => {
        const response = await statesList();
        setStates(response.data);
        console.log(response.data);
    }

    const handleChange = (value) => {
        const selectedValue = states.filter((state) => state.stateId === value)[0];
        console.log(selectedValue);
        setSelectedState(selectedValue);
        handleStateChange(selectedValue);
    }

    const handleStateChange = async (stateDetails) => {
        const response = await deliveryDetails(stateDetails);
        if (response.data?.length === 0) {
            setDeliveryData();
            setDeliveryCharge();
            setDeliveryCutOffAmount();
            setDeliveryTimePeriod();
        }
        else if (response.data?.length !== 0) {
            setDeliveryData(response.data[0]);
            setDeliveryCharge(response.data[0].deliveryCharge);
            setDeliveryCutOffAmount(response.data[0].deliveryCutOffAmount);
            setDeliveryTimePeriod(response.data[0].deliveryTimePeriod);
        }
    }

    const handleSubmit = async () => {

        const currDate = new Date().toISOString();
        const date = currDate.split('T')[0];
        const time = currDate.split('T')[1].slice(0, 8);
        const constructedTime = date + " " + time;

        const { deliverycharge, deliverycutoffamount, deliverytimeperiod } = formRef.current.getFieldValue();
        let payload;
        if (deliveryData) {
            payload = {
                createdBy: "VAD",
                createdDate: constructedTime,
                deliveryCharge: deliverycharge ? deliverycharge : deliveryData.deliveryCharge,
                deliveryCutOffAmount: deliverycutoffamount ? deliverycutoffamount : deliveryData.deliveryCutOffAmount,
                deliveryTimePeriod: deliverytimeperiod ? deliverytimeperiod : deliveryData.deliveryTimePeriod,
                modifiedBy: "VAD",
                modifiedDate: constructedTime,
                stateId: deliveryData.stateId,
                stateName: deliveryData.stateName,
                status: 1
            }

            await updateDeliveryCharge(payload, deliveryData.id);
        }
        else {
            payload = {
                createdBy: "VAD",
                createdDate: constructedTime,
                deliveryCharge: deliverycharge,
                deliveryCutOffAmount: deliverycutoffamount,
                deliveryTimePeriod: deliverytimeperiod,
                modifiedBy: "VAD",
                modifiedDate: constructedTime,
                stateId: selectedState.stateId,
                stateName: selectedState.stateName,
                status: 1
            }
            await saveDeliveryCharge(payload);
            formRef.current.resetFields();
            setSelectedState("ALL");
        }


    }


    return (
        <Layouts>
            <div className='w-full'>
                <div className='md:flex items-center justify-between md:mb-8 mb-4'>
                    <h1 className='md:text-2xl text-xl font-bold'>Delivery Details</h1>
                </div>
                <div  className='md:w-full w-max bg-white h-screen flex justify-center items-center rounded-xl'>
                    <div className='md:w-[50%] w-[85%] bg-lightBlue shadow rounded-lg md:p-6 p-3'>
                        <Form
                            ref={formRef}
                            key={deliveryData}
                            initialValues={{
                                deliverycharge: deliveryData ? deliveryData?.deliveryCharge : 0,
                                deliverytimeperiod: deliveryData ? deliveryData?.deliveryTimePeriod : 0,
                                deliverycutoffamount: deliveryData ? deliveryData?.deliveryCutOffAmount : 0,
                                state: selectedState ? selectedState.stateName : "ALL"
                            }}
                        >
                            <Form.Item name='state'>
                                <Select
                                    className='w-full bg-lightBlue border-2 rounded-lg md:h-[3rem] h-[2rem]'
                                    onChange={handleChange}
                                    defaultValue={"ALL"}
                                >
                                    {
                                        states?.map((state, idx) => (
                                            <Select.Option key={idx} value={state.stateId}>{state.stateName}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <div className='my-5'>
                                <div className='flex items-center justify-between my-2'>
                                    <p className='md:text-xl texl-base font-medium w-[45%]'>Delivery Charge </p>
                                    <Form.Item name="deliverycharge" className='w-[45%] '>
                                        <InputNumber className='w-full outline-none border-2 border-borderColor md:p-2 p-0.5 rounded-lg' />
                                    </Form.Item>
                                </div>
                                <div className='flex items-center justify-between my-2'>
                                    <p className='md:text-xl texl-base font-medium w-[45%]'>Delivery Time Period  </p>
                                    <Form.Item name="deliverytimeperiod" className='w-[45%] '>
                                        <InputNumber className='w-full outline-none border-2 border-borderColor md:p-2 p-0.5 rounded-lg' />
                                    </Form.Item>
                                </div>
                                <div className='flex items-center justify-between my-2'>
                                    <p className='md:text-xl texl-base font-medium w-[45%]'>Delivery CutOff Amount </p>
                                    <Form.Item name="deliverycutoffamount" className='w-[45%] '>
                                        <InputNumber className='w-full outline-none border-2 border-borderColor md:p-2 p-0.5 rounded-lg' />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>

                        <div className='flex justify-center items-center mx-4 my-6'>
                            <button
                                onClick={handleSubmit}
                                className='min-w-28 w-[50%] md:px-4 px-2 md:py-2 py-1 text-sm md:text-base rounded-lg md:mt-4 mt-2 border hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor'
                            >
                                {deliveryData ? "Update" : "Save"}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default Index;