import React, { useEffect, useState } from "react";
import Sidemenu from "../Shared/Sidemenu";
import Header from "../Shared/Header";

const Layouts = ({ children }) => {
    const [isMenuOpen, setMenuOpen] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 640) {
                setMenuOpen(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <div className="main_page">
            <div className="flex">
                <div className={`${(isMenuOpen ? 'w-[300px]' : 'md:w-[90px] w-[57px]')} h-screen`}>
                    <Sidemenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                </div>
                <div className="w-full">
                    <Header toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                    <div className={`md:w-full flex-1 ${window.innerWidth <= 640 ? 'ml-12' : ''} px-6 py-8 h-screen scrollBarWidth overflow-y-scroll`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layouts;

