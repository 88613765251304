// import React, { useState } from "react";
// import { Link } from 'react-router-dom';
// import Logo from "../../Assets/Images/Icons/logo.png";
// import { RxDashboard } from "react-icons/rx";
// import { BsHouseDash, BsCartCheck, BsCart4, BsFileExcel } from "react-icons/bs";
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
// import { PiDiamondBold } from "react-icons/pi";
// import { TbUserStar } from "react-icons/tb";
// import { RiLogoutBoxLine } from "react-icons/ri";
// import { LuUsers } from "react-icons/lu";
// import { BiSolidReport } from "react-icons/bi";
// import { MdOutlineContentPaste } from "react-icons/md";

// function Sidemenu({ isMenuOpen }) {
//     const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
//     const [itemClicked, setItemClicked] = useState('');

//     const handleItemClick = (itemName) => {
//         setItemClicked(prevState => prevState === itemName ? '' : itemName);
//         setIsSideDrawerOpen(!isSideDrawerOpen);
//     };
//     return (
//         <div className={`bg-white h-screen fixed z-10 custom-shadow ${isMenuOpen ? 'w-[252px]' : 'w-[90px]'}`}>
//             <div className={`h-20 p-5 flex gap-5 items-center border-b`}>
//                 <img src={Logo} alt="img" width={50} />
//                 <div className={`font-bold text-lg ${!isMenuOpen ? 'hidden' : ''}`}>AdminLogo</div>
//             </div>
//             <ul className="px-5 mt-5 flex gap-[0.5rem] flex-col">
//                 <li >
//                     <Link to={'/dashboard'} className="flex items-center gap-3 px-2 py-2  cursor-pointer">
//                     <div className="text-xl"><RxDashboard className={`${!isMenuOpen ? 'text-fontTwohalf' : ''}`} /></div> <span className={`font-medium text-base ${!isMenuOpen ? 'hidden' : ''}`}>Dashboard</span>
//                     </Link>
//                 </li>
//                 <li
//                 //  onClick={() => handleItemClick('facility')}
//                  >
//                     <div  className="flex items-center gap-3 px-2 py-2  cursor-pointer">
//                         <div className="text-xl"><BsHouseDash className={`${!isMenuOpen ? 'text-fontTwohalf' : ''}`} /></div> <div className="flex justify-between items-center w-full">
//                             <span className={`font-medium text-base ${!isMenuOpen ? 'hidden' : ''}`}>Facility</span>
//                             {itemClicked === 'facility' ? <IoIosArrowUp className={`${!isMenuOpen ? 'hidden' : ''}`} /> : <IoIosArrowDown className={`${!isMenuOpen ? 'hidden' : ''}`} />}
//                         </div>
//                     </div>
//                 </li>
//                 {
//                     isMenuOpen && (
//                         <ul className={`ml-5 ${itemClicked === 'facility' ? '' : 'hidden'}`}>
//                             <li className='flex gap-3 items-center px-4 py-2'>
//                                 <PiDiamondBold className="text-[10px]" />
//                                 <Link to={'/locationList'} className='font-normal text-sm'>Locations List</Link>
//                             </li>
//                         </ul>

//                     )
//                 }
//                 <li className="flex items-center gap-3 px-2 py-2  cursor-pointer" onClick={() => handleItemClick('product')}>
//                     <div className="text-xl"> <BsCartCheck className={`${!isMenuOpen ? 'text-fontTwohalf' : ''}`} /></div> <div className="flex justify-between items-center w-full">
//                         <span className={`font-medium text-base ${!isMenuOpen ? 'hidden' : ''}`}>Product</span>
//                         {itemClicked === 'product' ? <IoIosArrowUp className={`${!isMenuOpen ? 'hidden' : ''}`} /> : <IoIosArrowDown className={`${!isMenuOpen ? 'hidden' : ''}`} />}
//                     </div>
//                 </li>
//                 {
//                     isMenuOpen &&
//                     <ul className={`ml-5 ${itemClicked === 'product' ? '' : 'hidden'}`}>
//                         <li className='flex gap-3 items-center px-4 py-2'>
//                             <PiDiamondBold className="text-[10px]" />
//                             <Link to={'/product'} className='font-normal text-sm'>Product List</Link>
//                         </li>
//                         {/* <li className='flex gap-3 items-center px-4 py-2'>
//                             <PiDiamondBold className="text-[10px]" />
//                             <Link to={'/updateProduct'} className='font-normal text-sm'>Add Product</Link>
//                         </li> */}
//                         <li className='flex gap-3 items-center px-4 py-2'>
//                             <PiDiamondBold className="text-[10px]" />
//                             <Link to={'/productAvailability'} className='font-normal text-sm'>Product Availability</Link>
//                         </li>
//                         <li className='flex gap-3 items-center px-4 py-2'>
//                             <PiDiamondBold className="text-[10px]" />
//                             <Link to={'/shippingCharges'} className='font-normal text-sm'>Shipping Charges</Link>
//                         </li>
//                     </ul>
//                 }
//                 <li className="flex items-center gap-3 px-2 py-2  cursor-pointer" onClick={() => handleItemClick('user')}>
//                     <div className="text-xl"> <TbUserStar className={`${!isMenuOpen ? 'text-fontTwohalf' : ''}`} /></div> <div className="flex justify-between items-center w-full">
//                         <span className={`font-medium text-base ${!isMenuOpen ? 'hidden' : ''}`}>User</span>
//                         {itemClicked === 'user' ? <IoIosArrowUp className={`${!isMenuOpen ? 'hidden' : ''}`} /> : <IoIosArrowDown className={`${!isMenuOpen ? 'hidden' : ''}`} />}
//                     </div>
//                 </li>
//                 {
//                     isMenuOpen &&
//                     <ul className={`ml-5 ${itemClicked === 'user' ? '' : 'hidden'}`}>
//                         <li className='flex gap-3 items-center px-4 py-2'>
//                             <PiDiamondBold className="text-[10px]" />
//                             <Link to={'/users'} className='font-normal text-sm'>User List</Link>
//                         </li>
//                         {/* <li className='flex gap-3 items-center px-4 py-2'>
//                             <PiDiamondBold className="text-[10px]" />
//                             <Link to={'/addUser'} className='font-normal text-sm'>Add User</Link>
//                         </li> */}
//                     </ul>
//                 }
//                 <li className="flex items-center gap-3 px-2 py-2  cursor-pointer" onClick={() => handleItemClick('order')}>
//                     <div className="text-xl"> <BsCart4 className={`${!isMenuOpen ? 'text-fontTwohalf' : ''}`} /></div> <div className="flex justify-between items-center w-full">
//                         <span className={`font-medium text-base ${!isMenuOpen ? 'hidden' : ''}`}>Order</span>
//                         {itemClicked === 'order' ? <IoIosArrowUp className={`${!isMenuOpen ? 'hidden' : ''}`} /> : <IoIosArrowDown className={`${!isMenuOpen ? 'hidden' : ''}`} />}
//                     </div>
//                 </li>
//                 {
//                     isMenuOpen &&
//                     <ul className={`ml-5 ${itemClicked === 'order' ? '' : 'hidden'}`}>
//                         <li className='flex gap-3 items-center px-4 py-2'>
//                             <PiDiamondBold className="text-[10px]" />
//                             <Link to={'/orders'} className='font-normal text-sm'>All Orders</Link>
//                         </li>
//                         <li className='flex gap-3 items-center px-4 py-2'>
//                             <PiDiamondBold className="text-[10px]" />
//                             <Link to={'/orderDetails'} className='font-normal text-sm'>Order Details</Link>
//                         </li>
//                         {/* <li className='flex gap-3 items-center px-4 py-2'>
//                             <PiDiamondBold className="text-[10px]" />
//                             <Link to={'/orderTracking'} className='font-normal text-sm'>Order Tracking</Link>
//                         </li> */}
//                     </ul>
//                 }
//                 <li className="flex items-center gap-3 px-2 py-2  cursor-pointer" onClick={() => handleItemClick('content')}>
//                     <div className="text-xl"> <MdOutlineContentPaste className={`${!isMenuOpen ? 'text-fontTwohalf' : ''}`} /></div> <div className="flex justify-between items-center w-full">
//                         <span className={`font-medium text-base ${!isMenuOpen ? 'hidden' : ''}`}>Content</span>
//                         {itemClicked === 'content' ? <IoIosArrowUp className={`${!isMenuOpen ? 'hidden' : ''}`} /> : <IoIosArrowDown className={`${!isMenuOpen ? 'hidden' : ''}`} />}
//                     </div>
//                 </li>
//                 {
//                     isMenuOpen &&
//                     <ul className={`ml-5 ${itemClicked === 'content' ? '' : 'hidden'}`}>
//                         <li className='flex gap-3 items-center px-4 py-2'>
//                             <PiDiamondBold className="text-[10px]" />
//                             <Link to={'/headerscroll'} className='font-normal text-sm'>Add Contents</Link>
//                         </li>
//                     </ul>
//                 }
//                 <li onClick={() => handleItemClick('customer')}>
//                     <Link to={'/customers'} className="flex items-center gap-3 px-2 py-2  cursor-pointer">
//                         <div className="text-xl"> <LuUsers className={`${!isMenuOpen ? 'text-fontTwohalf' : ''}`} /></div> <div className="flex justify-between items-center w-full">
//                             <span className={`font-medium text-base ${!isMenuOpen ? 'hidden' : ''}`}>Customer</span>
//                         </div>
//                     </Link>
//                 </li>
//                 <li className="flex items-center gap-3 px-2 py-2  cursor-pointer">
//                     <div className="text-xl"> <BiSolidReport className={`${!isMenuOpen ? 'text-fontTwohalf' : ''}`} /></div> <div className="flex justify-between items-center w-full">
//                         <Link to={'/reports'} className={`font-medium text-base ${!isMenuOpen ? 'hidden' : ''}`}>Reports</Link>
//                     </div>
//                 </li>
//                 <li className="flex items-center gap-3 px-2 py-2  cursor-pointer">
//                     <div className="text-xl"> <RiLogoutBoxLine className={`${!isMenuOpen ? 'text-fontTwohalf' : ''}`} /></div> <div className="flex justify-between items-center w-full">
//                         <Link to={'/'} className={`font-medium text-base ${!isMenuOpen ? 'hidden' : ''}`}>Logout</Link>
//                     </div>
//                 </li>
//             </ul>

//         </div>
//     )
// }

// export default Sidemenu

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../Assets/Images/Icons/logo.png";
import { RxDashboard } from "react-icons/rx";
import { BsHouseDash, BsCartCheck, BsCart4, BsFileExcel } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { PiDiamondBold } from "react-icons/pi";
import { TbUserStar } from "react-icons/tb";
import { RiLogoutBoxLine } from "react-icons/ri";
import { LuUsers } from "react-icons/lu";
import { BiSolidReport } from "react-icons/bi";
import { MdOutlineContentPaste } from "react-icons/md";
import { Modal } from "antd";
import { secureStorage } from "../../functions/isAuth";

function Sidemenu({ isMenuOpen, toggleMenu }) {
    const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
    const [itemClicked, setItemClicked] = useState('');
    const [isMobileView, setIsMobileView] = useState(false);
    const [isLogoutClicked, setIsLogoutClicked] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 640);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleItemClick = (itemName) => {
        setItemClicked(prevState => prevState === itemName ? '' : itemName);
        if (isMobileView) {
            setIsSideDrawerOpen(false); // Close side menu on mobile view
        }
    };

    const handleLogout = () => {
        setIsLogoutClicked(true);
    }

    const handleCancel = () => {
        setIsLogoutClicked(false);
    }

    const handleLogoutConfirmaion = () => {
        secureStorage.removeItem('access_token');
        setIsLogoutClicked(false);
        navigate('/');
    }

    return (
        <>
            <div className={`bg-white h-screen fixed z-10 custom-shadow ${isMenuOpen && !isMobileView ? 'w-[252px]' : isMenuOpen && isMobileView ? 'w-[41.5%]' : 'md:w-[90px] w-[50px]'}`}>
                <div className={`h-20 md:p-5 flex gap-5 items-center border-b`}>
                    <img src={Logo} alt="img" className={`${isMenuOpen && isMobileView ? 'w-8 h-8' : 'object-cover'} md:w-16 md:h-16`} />
                    <div className={`md:font-bold font-medium text-base md:text-lg ${!isMenuOpen ? 'hidden' : ''}`}>AdminLogo</div>
                </div>
                <ul className={`px-5 mt-5 flex gap-[0.5rem] flex-col customHeight overflow-y-auto scrollBarWidth`}>
                    <li onClick={() => (isMobileView && !isMenuOpen) ? toggleMenu() : ''}>
                        <Link to={`${isMenuOpen ? '/dashboard' : ''}`} className="flex items-center gap-3 md:px-2 py-2  cursor-pointer">
                            <div><RxDashboard className={`${!isMenuOpen ? 'md:text-fontTwohalf text-[22px]' : 'md:text-2xl'}`} /></div> <span className={`font-medium md:text-lg text-sm ${!isMenuOpen ? 'hidden' : ''}`}>Dashboard</span>
                        </Link>
                    </li>
                    {/* Other menu items */}
                    <li
                    //  onClick={() => handleItemClick('facility')}
                    >
                        <div className="flex items-center gap-3 md:px-2 py-2  cursor-pointer">
                            <div><BsHouseDash className={`${!isMenuOpen ? 'md:text-fontTwohalf text-[22px]' : 'md:text-2xl'}`} /></div> <div className="flex justify-between items-center w-full">
                                <span className={`font-medium  md:text-lg text-sm ${!isMenuOpen ? 'hidden' : ''}`}>Facility</span>
                                {itemClicked === 'facility' ? <IoIosArrowUp className={`md:text-base text-sm ${!isMenuOpen ? 'hidden' : ''}`} /> : <IoIosArrowDown className={`md:text-base text-sm ${!isMenuOpen ? 'hidden' : ''}`} />}
                            </div>
                        </div>
                    </li>
                    {
                        isMenuOpen && (
                            <ul className={`ml-5 ${itemClicked === 'facility' ? '' : 'hidden'}`}>
                                <li className='flex gap-3 items-center px-4 py-2'>
                                    <PiDiamondBold className="md:text-[10px] text-[5px]" />
                                    <Link to={'/locationList'} className='md:font-normal font-extralight md:text-sm text-xs'>Locations List</Link>
                                </li>
                            </ul>

                        )
                    }
                    <li className="flex items-center gap-3 md:px-2 py-2  cursor-pointer"
                        onClick={() => {
                            handleItemClick('product');
                            if (isMobileView && !isMenuOpen) toggleMenu()
                        }}>
                        <div> <BsCartCheck className={`${!isMenuOpen ? 'md:text-fontTwohalf text-[22px]' : 'md:text-2xl'}`} /></div> <div className="flex justify-between items-center w-full">
                            <span className={`font-medium  md:text-lg text-sm ${!isMenuOpen ? 'hidden' : ''}`}>Product</span>
                            {itemClicked === 'product' ? <IoIosArrowUp className={`md:text-base text-sm ${!isMenuOpen ? 'hidden' : ''}`} /> : <IoIosArrowDown className={`md:text-base text-sm ${!isMenuOpen ? 'hidden' : ''}`} />}
                        </div>
                    </li>
                    {
                        isMenuOpen &&
                        <ul className={`ml-5 ${itemClicked === 'product' ? '' : 'hidden'}`}>
                            <li className='flex gap-3 items-center px-4 py-2'>
                                <PiDiamondBold className="md:text-[10px] text-[5px]" />
                                <Link to={'/productmaster'} className='md:font-normal font-extralight md:text-sm text-xs'>Product Master</Link>
                            </li>
                            <li className='flex gap-3 items-center px-4 py-2'>
                                <PiDiamondBold className="md:text-[10px] text-[5px]" />
                                <Link to={'/productlist'} className='md:font-normal font-extralight md:text-sm text-xs'>Product List</Link>
                            </li>
                            <li className='flex gap-3 items-center px-4 py-2'>
                                <PiDiamondBold className="md:text-[10px] text-[5px]" />
                                <Link to={'/productAvailability'} className='md:font-normal font-extralight md:text-sm text-xs'>Available Quantities</Link>
                            </li>
                            <li className='flex gap-3 items-center px-4 py-2'>
                                <PiDiamondBold className="md:text-[10px] text-[5px]" />
                                <Link to={'/shippingCharges'} className='md:font-normal font-extralight md:text-sm text-xs'>Shipping Charges</Link>
                            </li>
                        </ul>
                    }
                    <li className="flex items-center gap-3 md:px-2 py-2  cursor-pointer"
                        onClick={() => {
                            handleItemClick('user')
                            if (isMobileView && !isMenuOpen) toggleMenu()
                        }}>
                        <div> <TbUserStar className={`${!isMenuOpen ? 'md:text-fontTwohalf text-[22px]' : 'md:text-2xl'}`} /></div> <div className="flex justify-between items-center w-full">
                            <span className={`font-medium  md:text-lg text-sm ${!isMenuOpen ? 'hidden' : ''}`}>User</span>
                            {itemClicked === 'user' ? <IoIosArrowUp className={`md:text-base text-sm ${!isMenuOpen ? 'hidden' : ''}`} /> : <IoIosArrowDown className={`md:text-base text-sm ${!isMenuOpen ? 'hidden' : ''}`} />}
                        </div>
                    </li>
                    {
                        isMenuOpen &&
                        <ul className={`ml-5 ${itemClicked === 'user' ? '' : 'hidden'}`}>
                            <li className='flex gap-3 items-center px-4 py-2'>
                                <PiDiamondBold className="md:text-[10px] text-[5px]" />
                                <Link to={'/users'} className='md:font-normal font-extralight md:text-sm text-xs'>User List</Link>
                            </li>
                        </ul>
                    }
                    <li className="flex items-center gap-3 md:px-2 py-2  cursor-pointer"
                        onClick={() => {
                            handleItemClick('order')
                            if (isMobileView && !isMenuOpen) toggleMenu()
                        }}>
                        <div> <BsCart4 className={`${!isMenuOpen ? 'md:text-fontTwohalf text-[22px]' : 'md:text-2xl'}`} /></div> <div className="flex justify-between items-center w-full">
                            <span className={`font-medium md:text-lg text-sm ${!isMenuOpen ? 'hidden' : ''}`}>Order</span>
                            {itemClicked === 'order' ? <IoIosArrowUp className={`md:text-base text-sm ${!isMenuOpen ? 'hidden' : ''}`} /> : <IoIosArrowDown className={`md:text-base text-sm ${!isMenuOpen ? 'hidden' : ''}`} />}
                        </div>
                    </li>
                    {
                        isMenuOpen &&
                        <ul className={`ml-5 ${itemClicked === 'order' ? '' : 'hidden'}`}>
                            <li className='flex gap-3 items-center px-4 py-2'>
                                <PiDiamondBold className="md:text-[10px] text-[5px]" />
                                <Link to={'/orders'} className='md:font-normal font-extralight md:text-sm text-xs'>All Orders</Link>
                            </li>
                            <li className='flex gap-3 items-center px-4 py-2'>
                                <PiDiamondBold className="md:text-[10px] text-[5px]" />
                                <Link to={'/orderDetails'} className='md:font-normal font-extralight md:text-sm text-xs'>Order Details</Link>
                            </li>
                            {/* <li className='flex gap-3 items-center px-4 py-2'>
                            <PiDiamondBold className="md:text-[10px] text-[5px]" />
                            <Link to={'/orderTracking'} className='md:font-normal font-extralight md:text-sm text-xs'>Order Tracking</Link>
                        </li> */}
                        </ul>
                    }
                    <li className="flex items-center gap-3 md:px-2 py-2  cursor-pointer"
                        onClick={() => {
                            handleItemClick('content')
                            if (isMobileView && !isMenuOpen) toggleMenu()
                        }}>
                        <div> <MdOutlineContentPaste className={`${!isMenuOpen ? 'md:text-fontTwohalf text-[22px]' : 'md:text-2xl'}`} /></div> <div className="flex justify-between items-center w-full">
                            <span className={`font-medium  md:text-lg text-sm ${!isMenuOpen ? 'hidden' : ''}`}>Content</span>
                            {itemClicked === 'content' ? <IoIosArrowUp className={`md:text-base text-sm ${!isMenuOpen ? 'hidden' : ''}`} /> : <IoIosArrowDown className={`md:text-base text-sm ${!isMenuOpen ? 'hidden' : ''}`} />}
                        </div>
                    </li>
                    {
                        isMenuOpen &&
                        <ul className={`ml-5 ${itemClicked === 'content' ? '' : 'hidden'}`}>
                            <li className='flex gap-3 items-center px-4 py-2'>
                                <PiDiamondBold className="md:text-[10px] text-[5px]" />
                                <Link to={'/headerscroll'} className='md:font-normal font-extralight md:text-sm text-xs'>Add Contents</Link>
                            </li>
                        </ul>
                    }
                    <li onClick={() => {
                        handleItemClick('customer')
                        if (isMobileView && !isMenuOpen) toggleMenu()
                    }}>
                        <Link to={'/customers'} className="flex items-center gap-3 md:px-2 py-2  cursor-pointer">
                            <div> <LuUsers className={`${!isMenuOpen ? 'md:text-fontTwohalf text-[22px]' : 'md:text-2xl'}`} /></div> <div className="flex justify-between items-center w-full">
                                <span className={`font-medium  md:text-lg text-sm ${!isMenuOpen ? 'hidden' : ''}`}>Customer</span>
                            </div>
                        </Link>
                    </li>
                    <li className="flex items-center gap-3 md:px-2 py-2  cursor-pointer" onClick={() => {
                        handleItemClick('reports')
                        if (isMobileView && !isMenuOpen) toggleMenu()
                    }}>
                        <div> <BiSolidReport className={`${!isMenuOpen ? 'md:text-fontTwohalf text-[22px]' : 'md:text-2xl'}`} /></div> <div className="flex justify-between items-center w-full">
                            <Link to={'/reports'} className={`font-medium  md:text-lg text-sm ${!isMenuOpen ? 'hidden' : ''}`}>Reports</Link>
                        </div>
                    </li>
                    <li className="flex items-center gap-3 md:px-2 py-2  cursor-pointer" onClick={handleLogout}>
                        <div> <RiLogoutBoxLine className={`${!isMenuOpen ? 'md:text-fontTwohalf text-[22px]' : 'md:text-2xl'}`} /></div> <div className="flex justify-between items-center w-full">
                            <div className={`font-medium  md:text-lg text-sm ${!isMenuOpen ? 'hidden' : ''}`}>Logout</div>
                        </div>
                    </li>
                </ul>
            </div>
            <Modal
                open={isLogoutClicked}
                onCancel={handleCancel}
                footer={<div className='flex items-center gap-4 justify-end mx-4'>
                    <button
                        onClick={handleCancel}
                        className='min-w-28 px-4 py-2 rounded-lg mt-4 border-2 hover:bg-dangerColor border-dangerColor text-dangerColor hover:text-white hover:border-White'
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleLogoutConfirmaion}
                        className='min-w-28 px-4 py-2 rounded-lg mt-4 border-2 hover:bg-primaryColor text-primaryColor border-primaryColor hover:text-White hover:border-White'
                    >
                        Logout
                    </button>
                </div>}
            >
                <div className="h-[5rem] flex flex-col justify-between">
                    <p className="font-semibold text-xl">Are you sure want to logout from the app ?</p>

                </div>
            </Modal>
        </>
    );
}

export default Sidemenu;
