export const data = [
  {
    Product: "Ayurvedic Herbal Oil",
    HSN_Code: "330590",
    Category: "Kashayam",
    Discount: 10,
  },
  {
    Product: "Turmeric Capsules",
    Category: "Churnams",
    Discount: 15,
    HSN_Code: "330591"
  },
  {
    Product: "Neem Face Wash",
    Category: "Tailam",
    Discount: 5,
    HSN_Code: "330592"
  },
  {
    Product: "Ayurvedic Hand Sanitizer",
    Package: '250gm',
    Category: "Ghrithms",
    Discount: 5,
    HSN_Code: "330593"
  },
  {
    Product: "Herbal Immunity Drink",
    Category: "Lehams",
    Discount: 10,
    HSN_Code: "330594"
  },
  {
    Product: "Ayurvedic Sleep Aid Capsules",
    Package: '250gm',
    Category: "Kashayam",
    Discount: 8,
    HSN_Code: "330595"
  },
  {
    Product: "Herbal Cough Syrup",
    Category: "Churnams",
    Discount: 10,
    HSN_Code: "330596"
  },
  {
    Product: "Ayurvedic Digestive Enzymes",
    Category: "Tailam",
    Discount: 12,
    HSN_Code: "330597"
  },
  {
    Product: "Herbal Pain Relief Patch",
    Category: "Ghrithms",
    Discount: 8,
    HSN_Code: "330598"
  },
  {
    Product: "Ayurvedic Liver Tonic",
    Category: "Lehams",
    Discount: 10,
    HSN_Code: "330599"
  },
  {
    Product: "Ayurvedic Herbal Oil",
    Category: "Kashayam",
    Discount: 10,
    HSN_Code: "330600"
  },
  {
    Product: "Turmeric Capsules",
    Category: "Churnams",
    Discount: 15,
    HSN_Code: "330601"
  },
  {
    Product: "Neem Face Wash",
    Category: "Tailam",
    Discount: 5,
    HSN_Code: "330602"
  },
  {
    Product: "Ashwagandha Tablets",
    Category: "Ghrithms",
    Discount: 8,
    HSN_Code: "330603"
  },
  {
    Product: "Triphala Churna",
    Category: "Lehams",
    Discount: 12,
    HSN_Code: "330604"
  },
  {
    Product: "Ayurvedic Pain Relief Balm",
    Category: "Kashayam",
    Discount: 10,
    HSN_Code: "330605"
  },
  {
    Product: "Brahmi Oil",
    Category: "Churnams",
    Discount: 7,
    HSN_Code: "330606"
  },
];