import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import  Chart  from 'chart.js/auto';

function DoughNutChart() {
    const data = {
        labels: [
            'Active',
            'New',
        ],
        datasets: [{
            label: 'Customers',
            data: [300, 100],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
            ],
            hoverOffset: 4
        }]
    };
    const config = {
        type: 'doughnut',
        data: data,
        responsive:true
    };
    return (
        <div className='md:w-[80%] w-max md:h-[25rem]'>
            <Doughnut data={data} options={config} />
        </div>
    )
}

export default DoughNutChart