import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Layouts from '../../../Layouts';
import { Form, InputNumber } from 'antd';

function EditUser() {
    const navigate = useNavigate();
    const formRef = useRef();

    const handleClick = () => {
        const values = formRef.current.getFieldValue();
        console.log(values);
        navigate('/users');
    }


    return (
        <Layouts>
            <div>
                <div className='flex items-center justify-between mb-8'>
                    <h1 className='text-2xl font-bold'>Edit User</h1>
                </div>
                <div className='px-8 py-4 my-8 bg-white rounded-lg h-screen'>

                    <Form ref={formRef}>
                        <div className='w-[50%] flex flex-col justify-center '>
                            <div className='flex flex-col bg-white rounded-xl p-6 mx-1'>
                                <Form.Item name="name">
                                    <div className='my-2'>
                                        <div>
                                            <div className='my-2'><label className='text-lg font-medium'>User Name</label></div>
                                            <input type='text' className='w-full outline-none border-borderColor border-2 p-2 rounded-lg' />
                                        </div>
                                    </div>
                                </Form.Item>
                                <Form.Item name="email">
                                <div className='my-2'>
                                    <div>
                                        <div className='my-2'><label className='text-lg font-medium'>Email</label></div>
                                        <input type='email' className='w-full outline-none border-borderColor border-2 p-2 rounded-lg' />
                                    </div>
                                </div>
                                </Form.Item>
                                <Form.Item name="contact">
                                <div className='my-2'>
                                    <div>
                                        <div className='my-2'><label className='text-lg font-medium'>Contact</label></div>
                                        <InputNumber className='w-full outline-none border-borderColor border-2 p-2 rounded-lg' />
                                    </div>
                                </div>
                                </Form.Item>
                               
                            </div>
                            <div className='flex gap-3 p-6'>
                                <button className='min-w-32 p-3 bg-primaryColor text-white rounded-lg font-semibold' onClick={handleClick}>Edit User</button>
                                <button className='min-w-32 p-3 border border-dangerColor text-dangerColor rounded-lg font-semibold custom-button-shadow hover:text-white' onClick={handleClick}>Cancel</button>
                            </div>
                        </div>
                    </Form>
                </div>

            </div>
        </Layouts>
    );
}

export default EditUser;
