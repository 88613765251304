import React, { useState } from 'react';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import Pagination from '../../Shared/Pagination';
import { CiImageOn } from "react-icons/ci";

function Table({ data, page, handleModal, handleEditClick,handleImageIconClick }) {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(data?.length / itemsPerPage);

    return (
        <div>
            <div className={`bg-white ${data? 'flex-1':'w-full'} rounded-lg flex justify-center`}>
                <table className='w-full m-0'>
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>HSN Code</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Packages</th>
                            <th colSpan={3}>Action</th>
                        </tr>
                        <tr>
                            <td className='p-2' colSpan={12}></td>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {
                            currentItems?.map((item, idx) => (
                                <tr key={idx}>
                                    <td>{item.name}</td>
                                    <td>{item.hsnCode}</td>
                                    <td>{item.productCategoryCode}</td>
                                    <td>{item.productSubCategoryCode.length > 15?`${item.productSubCategoryCode.slice(0,15)}...`:item.productSubCategoryCode}</td>
                                    <td>
                                       {
                                        item?.productPackageDetails?.map((size,idx)=>(<span key={size.id}>{size.packageSize}{idx !== item?.productPackageDetails?.length-1? ", ":""}</span>))
                                       }
                                    </td>
                                    <td>
                                        <div className='flex items-center md:gap-6 gap-3'>
                                            <MdOutlineRemoveRedEye className='text-primaryColor md:text-lg text-sm' onClick={() => handleModal('view', item)} />
                                            <BiEditAlt className='text-successColor md:text-lg text-sm' onClick={() => handleEditClick(item)} />
                                            <CiImageOn className='text-primaryColor md:text-lg text-sm' onClick={() =>handleImageIconClick(item)}/>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end md:mt-[8rem] mt-[4rem]">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                    containerClassName="mb-4"
                    buttonClassName="border border-gray-300 md:px-3 px-2 rounded-full md:py-1 py-0.5 transition duration-300 ease-in-out focus:outline-none hover:bg-gray-200"
                    activeButtonClassName="bg-blue-500 rounded-full text-white"
                />
            </div>
        </div>
    );
}

export default Table;
