import { message } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import { secureStorage } from "./isAuth";
const baseUrl = process.env.REACT_APP_BASE_URL;
const token = secureStorage.getItem('access_token');

export const getProducts = async() => {
    
    try{
        const response = await axios.get(`${baseUrl}/productMaster/list/filter`,{headers:{
            Authorization:`Bearer ${token}`
        }});
        console.log(response);
        return response;

    }catch(e){
        return e;
    }
}

export const getProductQty = async () => {

    try{
        const response = await axios.get(`${baseUrl}/productQty/list/filter`,{headers:{
            Authorization:`Bearer ${token}`
        }});
        console.log(response);
        return response;

    }catch(e){
        return e;
    }
}
export const getApprovedProductQty = async () => {

    try{
        const response = await axios.get(`${baseUrl}/ProductQtyDetails/list/filter`,{headers:{
            Authorization:`Bearer ${token}`
        }});
        console.log(response);
        return response;

    }catch(e){
        return e;
    }
}

export const addProduct = async(payload) => {
    try{
        const response = await axios.post(`${baseUrl}/productMaster/`,payload,{headers:{
            Authorization:`Bearer ${token}`
        }});
       message.success("Product added successfully.");
    }catch(e){
        message.error("Error occurred");
    }
}

export const editProduct = async(payload,id) => {
    try{
        const response = await axios.put(`${baseUrl}/productMaster/${id}`,payload,{headers:{
            Authorization:`Bearer ${token}`
        }});
       message.success("Product added successfully.");
    }catch(e){
        message.error("Error occurred");
    }
}

export const updateInventory = async(payload) => {
    try{
        const response = await axios.post(`${baseUrl}/productQty/`,payload,{headers:{
            Authorization:`Bearer ${token}`
        }});
       message.success("Inventory added successfully.");
    }catch(e){
        message.error("Error occurred");
    }
}

export const editInventory = async(payload,itemId) => {
    try{
        const response = await axios.put(`${baseUrl}/productQty/${itemId}`,payload,{headers:{
            Authorization:`Bearer ${token}`
        }});
    }
    catch(e){
        message.error("Error occurred");
    }
}

export const approveInventory = async(payload) => {
    
    try{
        const response = await axios.post(`${baseUrl}/ProductQtyDetails/`,payload,{headers:{
            Authorization:`Bearer ${token}`
        }});
       message.success("Inventory approved successfully.");
    }catch(e){
        message.error("Error occurred");
    }
}

export const editApprovedInventory = async(payload,itemId) => {
    try{
        const response = await axios.put(`${baseUrl}/ProductQtyDetails/${itemId}`,payload,{headers:{
            Authorization:`Bearer ${token}`
        }});
    }
    catch(e){
        message.error("Error occurred");
    }
}

export const getPackageDetails = async () => {

    try{
        const response = await axios.get(`${baseUrl}/packageMaster/list/filter`,{headers:{
            Authorization:`Bearer ${token}`
        }});
        console.log(response);
        return response;

    }catch(e){
        return e;
    }
}

const addProductImages = async (payload) => {
    try{
        const response = await axios.post(`${baseUrl}/productImagesDetails/`,payload,{headers:{
            Authorization:`Bearer ${token}`
        }});
       message.success("Image added successfully.");
    }catch(e){
        message.error("Error occurred");
    }
}

const editProductImages = async (payload,id) => {
    try{
        await axios.put(`${baseUrl}/productImagesDetails/${id}`,payload,{
            headers:{
            Authorization:`Bearer ${token}`
        }});
        message.success("Image edited successfully.");
      
    }catch(e){
        message.error("Error occurred");
    }
}
export const debouncedEditProductImages = debounce(editProductImages,300);
export const debouncedAddProductImages = debounce(addProductImages, 300);

export const getProductImages = async(productCode) => {
    try{
        const response = await axios.get(`${baseUrl}/productImagesDetails/list/filter`,{
            params:{
                productCode
            },
            headers:{
                Authorization:`Bearer ${token}`,
            }
        });
        return response;
    }
    catch(e){
        return e;
    }
}

export const deliveryDetails = async(stateData) => {
    try{
        const response = await axios.get(`${baseUrl}/productShippingDetails/list/filter`,
        {
            params:{
                stateId:stateData.stateId
            },
            headers:{
                Authorization:`Bearer ${token}`,
            }
        });
        console.log(response);
        return response;

    }catch(e){
        return e;
    }
}

export const saveDeliveryCharge = async(payload) => {
    try{
        const response = await axios.post(`${baseUrl}/productShippingDetails/`,payload,{headers:{
            Authorization:`Bearer ${token}`
        }});
    }
    catch(e){
        message.error("Error occurred");
    }
}

export const updateDeliveryCharge = async(payload,itemId) => {
    try{
        const response = await axios.put(`${baseUrl}/productShippingDetails/${itemId}`,payload,{headers:{
            Authorization:`Bearer ${token}`
        }});
    }
    catch(e){
        message.error("Error occurred");
    }
}

export const statesList = async() => {
    try{
        const response = await axios.get(`${baseUrl}/stateMaster/list/filter`,{headers:{
            Authorization:`Bearer ${token}`
        }});
        console.log(response);
        return response;

    }catch(e){
        console.log(e);
    }
}




