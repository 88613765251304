import React from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

function LineChart() {
  const labels = ['Jan 22', 'Apr 22', 'Jul 22', 'Oct 22', 'Jan 23', 'Apr 23', 'Jul 23', 'Oct 23', 'Jan 24'];
  const data = {
    labels: labels,
    datasets: [{
      label: 'Quarterly (In Lakhs)',
      data: [13, 24, 35, 30, 32, 37, 39, 45, 57],
      fill: false,
      borderColor: '#2B77FB',
      tension: 0.1
    }]
  };
  const config = {
    type: 'line',
    data: data,
    responsive: true
  };
  return (
    <div className='md:w-[80%] md:h-[25rem]'>
      <Line data={data} options={config} />
    </div>
  )
}

export default LineChart