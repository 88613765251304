import { message } from "antd";
import axios from "axios";
import { secureStorage } from "./isAuth";
const baseUrl = process.env.REACT_APP_BASE_URL;
const token = secureStorage.getItem('access_token');

export const User = async (code) => {
    try {
        const response = await axios.get(`${baseUrl}/user/list/filter`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response);
        return response;

    } catch (e) {
        return e;
    }
}

export const addUser = async (payload) => {

    try {
        const response = await axios.post(`${baseUrl}/user/`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        message.success("User added successfully");

    } catch (e) {
        message.error("Error occurred");
    }
}

export const editUser = async (payload, id) => {

    try {
        const response = await axios.put(`${baseUrl}/user/${id}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        message.success("User edited successfully");

    } catch (e) {
        message.error("Error occurred");
    }
}