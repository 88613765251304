import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdCloseCircle } from "react-icons/io";
import { BiEditAlt } from 'react-icons/bi';
import { Modal } from "antd";


function EditModal({ selectedItem,handleFieldValueChange, handleModal, type, modalState }) {
    const navigate = useNavigate();
    const [itemIdx, setItemIdx] = useState();
    const [currEditItem, setCurrEditItem] = useState('');
    const [fieldType,setFieldType] = useState('');
    const [currInput,setCurrInput] = useState('');

    console.log(selectedItem);

    const handleEditClick = () => {
        const keys = Object.keys(selectedItem);
        if (keys.includes('Product')) {
            navigate('/editProduct');
        }
        else if (keys.includes('Locationcode')) {
            navigate('/editLocation');
        }
        else if (keys.includes('User')) {
            navigate('/editUser');
        }
    };

    const handleClick = (item, idx,selectedType) => {
        setItemIdx(idx);
        setCurrEditItem(item);
        setFieldType(selectedType);
    }

    const handleUpdate = () => {
        const item = selectedItem.find((item, idx) => idx === itemIdx);
        if(currInput !== item.fieldType){
            const idx = selectedItem.findIndex((item,idx) =>  idx === itemIdx);
            handleFieldValueChange(idx,currInput,type);
        } 
        setItemIdx();
        setCurrEditItem();
        setFieldType();
    }

    const handleChange = (e) => {
        setCurrInput(e.target.value);
    }

    const keys = Object.keys(selectedItem);
    console.log(keys);


    // return (
    //     <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999999]'>
    //         {/* <div className='gradient-edit-modal'></div> */}
    //         <div className='min-w-[50rem] bg-white p-8 rounded-lg overflow-scroll scrollBarWidth'>
    //             <div className='flex justify-between text-3xl cursor-pointer py-1' onClick={() => handleModal(type)}>
    //                 <h2 className='text-2xl font-bold mb-4'>Preview</h2>
    //                 <IoMdCloseCircle />
    //             </div>
    //             {/* <div className='border-t-borderColor py-3 ' style={{ borderTopWidth: '2px' }}>
    //                 <h2 className='text-2xl font-bold mb-4'>Preview</h2>
    //             </div> */}
    //             <div className=''>
    //                 {
    //                     selectedItem.map((item, idx) => {
    //                         if (itemIdx === idx) {
    //                             console.log("hiiii");
    //                             return (
    //                                 <div className='flex items-center'>
    //                                     <div className=' w-full flex items-center'>
    //                                         <input type='text' onChange={handleChange} className='w-[53%] outline-none border-2 border-borderColor p-2 rounded-lg' />
    //                                     </div>
    //                                     <button
    //                                         onClick={() => handleClick(item, idx)}
    //                                         className='min-w-28 px-4 py-2 rounded-lg mt-4 border-2 hover:bg-successColor hover:text-white border-successColor text-successColor'
    //                                     >
    //                                         Update
    //                                     </button>
    //                                 </div>
    //                             )

    //                         }
    //                         else {
    //                             if (type === "benefits" || type === "howToUse") {
    //                                 return(<div className='flex items-center'>
    //                                     <div className=' w-full flex items-center'>
    //                                         <p className='w-[45%] border p-3 m-2 border-borderColor rounded-lg'>{item.description}</p>
    //                                     </div>
    //                                     <BiEditAlt className='text-successColor' onClick={() => handleClick(item, idx)} />
    //                                 </div>)
    //                             }
    //                             else if (type === "ingredients") {
    //                                 return(<div className='flex items-center'>
    //                                     <div className=' w-full flex items-center'>
    //                                         <p className='w-[45%] border p-3 m-2 border-borderColor rounded-lg'>{item.ingredientName}</p>
    //                                     </div>
    //                                     <BiEditAlt className='text-successColor' onClick={() => handleClick(item, idx)} />
    //                                 </div>)
    //                             }
    //                             // else {
    //                             //     return (
    //                             //         <div className='flex items-center'>
    //                             //             <div className=' w-full flex items-center'>
    //                             //                 <p className='w-[45%] border p-3 m-2 border-borderColor rounded-lg'>{selectedItem ? selectedItem[item] : ''}</p>
    //                             //             </div>
    //                             //             <BiEditAlt className='text-successColor' onClick={() => handleClick(item, idx)} />
    //                             //         </div>
    //                             //     )

    //                             // }

    //                         }
    //                     })
    //                 }

    //             </div>

    //         </div>
    //     </div>
    // );
    return (
        <Modal
            open={modalState}
            onCancel={()=>handleModal(type)}
            width={"50%"}
            footer={[]}
            className='h-[60%] overflow-scroll scrollBarWidth rounded-lg '
            title={<h2 className='text-2xl font-bold mb-4'>Preview</h2>}
        >
            <div className=' bg-white p-8 rounded-lg overflow-scroll scrollBarWidth'>
                {/* <div className='flex justify-between text-3xl cursor-pointer py-1' onClick={() => handleModal(type)}>
                    <h2 className='text-2xl font-bold mb-4'>Preview</h2>
                    <IoMdCloseCircle />
                </div> */}
                {/* <div className='border-t-borderColor py-3 ' style={{ borderTopWidth: '2px' }}>
                    <h2 className='text-2xl font-bold mb-4'>Preview</h2>
                </div> */}
                <div className=''>
                    {
                        selectedItem.map((item, idx) => {
                            if (itemIdx === idx) {
                                return (
                                    <div className='flex items-center'>
                                        <div className=' w-full flex items-center'>
                                            <input type='text' onChange={handleChange} className='w-[53%] outline-none border-2 border-borderColor p-2 rounded-lg' />
                                        </div>
                                        <button
                                            onClick={handleUpdate}
                                            className='min-w-28 px-4 py-2 rounded-lg mt-4 border-2 hover:bg-successColor hover:text-white border-successColor text-successColor'
                                        >
                                            Update
                                        </button>
                                    </div>
                                )

                            }
                            else {
                                if (type === "benefits" || type === "howToUse") {
                                    return (<div className='flex items-center'>
                                        <div className=' w-full flex items-center'>
                                            <p className='w-[45%] border p-3 m-2 border-borderColor rounded-lg'>{item.description}</p>
                                        </div>
                                        <BiEditAlt className='text-successColor' onClick={() => handleClick(item, idx,"description")} />
                                    </div>)
                                }
                                else if (type === "ingredients") {
                                    return (<div className='flex items-center'>
                                        <div className=' w-full flex items-center'>
                                            <p className='w-[45%] border p-3 m-2 border-borderColor rounded-lg'>{item.ingredientName}</p>
                                        </div>
                                        <BiEditAlt className='text-successColor' onClick={() => handleClick(item, idx,"ingredientName")} />
                                    </div>)
                                }
                                // else {
                                //     return (
                                //         <div className='flex items-center'>
                                //             <div className=' w-full flex items-center'>
                                //                 <p className='w-[45%] border p-3 m-2 border-borderColor rounded-lg'>{selectedItem ? selectedItem[item] : ''}</p>
                                //             </div>
                                //             <BiEditAlt className='text-successColor' onClick={() => handleClick(item, idx)} />
                                //         </div>
                                //     )

                                // }

                            }
                        })
                    }

                </div>

            </div>
        </Modal>
    )
}

export default EditModal;
