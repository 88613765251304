import React, { useEffect, useState } from 'react'
import Layouts from '../../Layouts';
import Table from './CustomerTable.jsx'
import { MdOutlineTipsAndUpdates } from 'react-icons/md';
import { IoSearchOutline } from 'react-icons/io5';
import { CustomerData } from '../../../data/customerData.js'
import Modal from '../../Shared/Modal.jsx';
import { useNavigate } from 'react-router-dom';
import ShimmerEffect from '../../Shared/ShimmerEffect.jsx'
import { secureStorage } from '../../../functions/isAuth.js';


function Customers() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const arr = new Array(5).fill(0);


  useEffect(() => {
    const token = secureStorage.getItem('access_token');
    if (!token) {
      navigate('/');
    }
  }, []);



  const handleModal = (actionType, selectedObj) => {
    setIsModalOpen(prevState => !prevState);
    if (actionType === 'view') {
      setSelectedItem(selectedObj)
    }
  }
  return (
    <>
      <Layouts>
        <div>
          <div className='md:flex items-center justify-between md:mb-8 mb-4'>
            <h1 className='md:text-2xl text-xl font-bold'>All Customers</h1>
          </div>
          <div className='w-full bg-white rounded-xl md:p-8 p-4'>
            <div className='flex gap-2 items-center text-textGray md:text-sm text-xs'><MdOutlineTipsAndUpdates className='md:text-base text-sm text-primaryColor' />Tip : Enhance your search experience by using the email or username filter.</div>
            <div>
              <div className='flex justify-between items-center py-6'>
                <div className='flex items-center'>
                  <div className='md:w-80 w-[inherit] flex items-center justify-between border border-gray-400 rounded-lg md:py-2 py-1 md:px-5 px-3'>
                    <input type='text' placeholder='Search here...' className='md:w-[95%] w-[inherit] outline-none md:placeholder:text-base placeholder:text-sm' />
                    <IoSearchOutline className="md:text-xl text-sm" />
                  </div>
                </div>
              </div>
            </div>
            {
              CustomerData ?
                (<Table data={CustomerData} handleModal={handleModal} />) :
                (<div className='flex flex-col gap-3 items-center'>
                  {
                    arr.map((idx) => (<ShimmerEffect key={idx} />))
                  }
                </div>)}
          </div>

        </div>
      </Layouts>
      {isModalOpen && <Modal selectedItem={selectedItem} isModalOpen={isModalOpen} handleModal={handleModal} />}
    </>
  )
}

export default Customers;
