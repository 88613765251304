import React, { useState } from 'react';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import Pagination from '../../Shared/Pagination';
import { CiImageOn } from "react-icons/ci";

function ContentTable({ fields, tableFields, data }) {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(data?.length / itemsPerPage);

    return (
        <div>
            <div className='bg-white w-full rounded-lg flex justify-center'>
                <table className='w-full m-0'>
                    <thead>
                        <tr>
                            {
                                fields.map((fieldName, idx) => (
                                    <th key={idx}>{fieldName}</th>
                                ))
                            }
                        </tr>
                        <tr>
                            <td className='p-2' colSpan={fields.length}></td>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {
                            currentItems?.map((item, idx) => (
                                <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    {
                                        tableFields.map((field, index) => (
                                            <td key={index}>
                                                {field === "description" ?
                                                    item[field].length > 40 ?
                                                        `${item[field].slice(0, 40)}...more` : item[field] :
                                                    item[field]
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end mt-[8rem]">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                    containerClassName="mb-4"
                    buttonClassName="border border-gray-300 md:px-3 px-2 rounded-full md:py-1 py-0.5 transition duration-300 ease-in-out focus:outline-none hover:bg-gray-200"
                    activeButtonClassName="bg-blue-500 rounded-full text-white"
                />
            </div>
        </div>
    );
}

export default ContentTable;
