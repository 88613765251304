import React, { useEffect, useRef, useState } from 'react'
import Layouts from '../../../Layouts'
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker, Form, Input, InputNumber, Select, message } from 'antd';
import { addUser, editUser } from '../../../../functions/user';
import { Option } from 'antd/es/mentions';
import { statesList } from '../../../../functions/products';
import { secureStorage } from '../../../../functions/isAuth';


function AddUser() {
    const navigate = useNavigate();
    const [dob, setDob] = useState();
    const [permissions, setPermissions] = useState({
        addProduct: {
            allow: false, deny: false
        },
        updateProduct: {
            allow: false, deny: false
        },
        deleteProduct: {
            allow: false, deny: false
        },
        discount: {
            allow: false, deny: false
        },
        coupon: {
            allow: false, deny: false
        }
    });
    const [states, setStates] = useState();
    const formRef = useRef();
    const { state } = useLocation();

    useEffect(() => {
        const token = secureStorage.getItem('access_token');
        if (!token) {
            navigate('/');
        }
        else {
            getAllStates();
        }
    }, []);


    const getAllStates = async () => {
        const response = await statesList();
        setStates(response.data);
    }

    const handleCancelClick = () => {
        navigate('/users');
    }

    const handleChange = (e) => {
        if (e.target.id === 'allow') {

            setPermissions(prevState => ({
                ...prevState, [e.target.name]: {
                    allow: true,
                    deny: false
                }
            }));
        }
        else if (e.target.id === 'deny') {
            setPermissions(prevState => ({
                ...prevState, [e.target.name]: {
                    allow: false,
                    deny: true
                }
            }));
        }
    }

    const handleDob = (value, valueString) => {
        const currDate = value?.$d.toISOString();
        const date = currDate.split('T')[0];
        const time = currDate.split('T')[1].slice(0, 8);
        const constructedTime = date + " " + time;
        setDob(valueString);
    }

    const handleKeyPress = (e) => {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
        if (!allowedKeys.includes(e.key)) {
            if (/^[A-Za-z]$/.test(e.key)) {
                message.error("Alphabetic characters are not allowed.");
            }
            e.preventDefault();
        }
    };



    const handleUserSave = async () => {
        let payload = {};
        console.log(formRef.current.getFieldValue());
        const currDate = new Date().toISOString();
        const date = currDate.split('T')[0];
        const time = currDate.split('T')[1].slice(0, 8);
        const constructedTime = date + " " + time;

        const { salutation, firstname, lastname, gender, mobile, pincode, userstate, city, country, email, password, confirmpassword } = formRef.current.getFieldValue();

        if (state?.user.salutation === undefined || state?.user.salutation === "" || state?.user.salutation === null) {
            if (salutation === "" || salutation === undefined) {
                message.error("Please fill in all mandatory fields");
            }
        }

        if (state?.user.firstName === undefined || state?.user.firstName === "" || state?.user.firstName === null) {
            if (firstname === "" || firstname === undefined) {
                message.error("Please fill in all mandatory fields");
            }
        }

        if (state?.user.lastName === undefined || state?.user.lastName === "" || state?.user.lastName === null) {
            if (lastname === "" || lastname === undefined) {
                message.error("Please fill in all mandatory fields");
            }
        }

        if (state?.user.email === undefined || state?.user.email === "" || state?.user.email === null) {
            if (email === "" || email === undefined) {
                message.error("Please fill in all mandatory fields");
            }
        }

        if (state?.user.gender === undefined || state?.user.gender === "" || state?.user.gender === null) {
            if (gender === "" || gender === undefined) {
                message.error("Please fill in all mandatory fields");
            }
        }

        if (state?.user.mobile === undefined || state?.user.mobile === "" || state?.user.mobile === null) {
            if (mobile === "" || mobile === undefined) {
                message.error("Please fill in all mandatory fields");
            }
        }

        if (state?.user.pincode === undefined || state?.user.pincode === "" || state?.user.pincode === null) {
            if (pincode === "" || pincode === undefined) {
                message.error("Please fill in all mandatory fields");
            }
        }

        if (state?.user.state === undefined || state?.user.state === "" || state?.user.state === null) {
            if (userstate === "" || userstate === undefined) {
                message.error("Please fill in all mandatory fields");
            }
        }

        if (state?.user.city === undefined || state?.user.city === "" || state?.user.city === null) {
            if (city === "" || city === undefined) {
                message.error("Please fill in all mandatory fields");
            }
        }

        if (state?.user.country === undefined || state?.user.country === "" || state?.user.country === null) {
            if (country === "" || country === undefined) {
                message.error("Please fill in all mandatory fields");
            }
        }

        if (state?.user) {
            payload = {
                address1: city ? city : state?.user.address1,
                city: city ? city : state?.user.city,
                country: country ? country : state?.user.country,
                createdBy: firstname ? firstname : state?.user.firstName,
                createdDate: state?.user.createdDate,
                dob: dob ? dob : state?.user.dob,
                email: email ? email : state?.user.email,
                firstName: firstname ? firstname : state?.user.firstName,
                gender: gender ? gender : state?.user.gender,
                lastName: lastname ? lastname : state?.user.lastName,
                mobile: mobile ? mobile : state?.user.mobile,
                modifiedBy: firstname ? firstname : state?.user.firstName,
                modifiedDate: constructedTime,
                photo: null,
                photoName: "",
                photoType: null,
                pincode: pincode ? pincode : state?.user.pincode,
                rolecode: "user",
                salutation: salutation ? salutation : state?.user.salutation,
                state: userstate ? userstate : state?.user.state,
                status: 1,
                userId: "string",
                userName: firstname ? firstname : state?.user.firstName
            }
            await editUser(payload, state?.user.id);
            navigate('/users');
        }
        else {
            payload = {
                address1: city,
                city: city,
                country: country,
                createdBy: firstname,
                createdDate: constructedTime,
                dob: dob,
                email: email,
                firstName: firstname,
                gender: gender,
                lastName: lastname,
                mobile: mobile,
                modifiedBy: firstname,
                modifiedDate: constructedTime,
                photo: null,
                photoName: "",
                photoType: null,
                pincode: pincode,
                rolecode: "user",
                salutation: salutation,
                state: userstate,
                status: 1,
                userId: "string",
                userName: firstname
            }
            await addUser(payload);
            navigate('/users');
        }



    }

    return (
        <Layouts>
            <div>
                <div className='md:flex items-center justify-between md:mb-8 mb-4'>
                    <h1 className='md:text-2xl text-xl font-bold'>{state?.user ? "Edit User" : "Add New User"}</h1>
                </div>
                <div>
                    <div className=' px-8 py-4 my-8 bg-white rounded-lg'>
                        <div>
                            <h2 className='md:text-2xl text-lg font-bold'>Account</h2>
                            <span className='text-textGray md:text-base text-sm'>Fill in the information to create a new user</span>
                            <Form ref={formRef}
                                className='w-full grid md:grid-cols-3 grid-cols-1 md:justify-center md:gap-5 md:items-center'
                                initialValues={{
                                    salutation: state?.user ? state?.user.salutation : '',
                                    firstname: state?.user ? state?.user.firstName : '',
                                    lastname: state?.user ? state?.user.lastName : '',
                                    email: state?.user ? state?.user.email : '',
                                    gender: state?.user ? state?.user.gender === "M" ? "Male" : "Female" : '',
                                    mobile: state?.user ? state?.user.mobile : '',
                                    pincode: state?.user ? state?.user.pincode : '',
                                    userstate: state?.user ? state?.user.state : '',
                                    city: state?.user ? state?.user.city : '',
                                    country: state?.user ? state?.user.country : ''
                                }}
                            >

                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Salutation</label><span className='text-dangerColor'>*</span></div>
                                        <Form.Item
                                            name="salutation"
                                            className='md:w-3/5 w-[inherit] md:p-2 p-1'
                                        >
                                            <Select placeholder="Salutation" className='md:h-12 h-10 outline-none border-2 rounded-lg'>
                                                <Select.Option value="Mr">Mr</Select.Option>
                                                <Select.Option value="Mrs">Mrs</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>First Name</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="firstname"
                                        >
                                            <Input type='text' placeholder='FirstName' name='firstname' className='md:w-3/5 w-full md:h-12 h-10 md:p-2 outline-none border-2 p-1 rounded-lg' />
                                        </Form.Item>
                                    </div>
                                </div>


                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Last Name</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="lastname"
                                            rules={[
                                                { required: true, message: "Please Enter name" },
                                                { type: 'text', message: "Don't leave blank" }
                                            ]}
                                        >
                                            <Input type='text' placeholder='LastName' name='lastname' className='md:w-3/5 w-full md:h-12 h-10 md:p-2 outline-none border-2 p-1 rounded-lg' />
                                        </Form.Item>
                                    </div>
                                </div>

                                <Form.Item name="dob" className='md:w-3/5 w-full p-2'>
                                    <div className='my-3'>
                                        <div className='my-2'>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>Date Of Birth</label><span className='text-red-500'>*</span></div>
                                            <DatePicker
                                                placeholder="Date of birth"
                                                format="MM/DD/YYYY"
                                                className="w-full md:h-12 h-10 border-2 rounded-lg"
                                                onChange={handleDob}
                                            />
                                        </div>
                                    </div>
                                </Form.Item>

                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Email</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                { type: 'email', message: 'Please enter a valid email address!' },
                                            ]}
                                        >
                                            <Input type='email' placeholder='Enter email' name='email' className='md:w-3/5 w-full  md:h-12 h-10 outline-none border-2 p-2 rounded-lg' />
                                        </Form.Item>
                                    </div>
                                </div>


                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Gender</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="gender"
                                            className='md:w-3/5 w-full p-2'
                                        >
                                            <Select placeholder="Select Gender" className='md:h-12 h-10 border-2 rounded-lg'>
                                                <Select.Option value="Male">Male</Select.Option>
                                                <Select.Option value="Female">Female</Select.Option>
                                                <Select.Option value="Others">Others</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>


                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Mobile</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="mobile"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    pattern: /^\d{10}$/, // Only allow 10 digits
                                                    message: 'Please enter a valid 10-digit mobile number!',
                                                }
                                            ]}
                                        >
                                            <Input placeholder='Enter mobile No' name='mobile' className='md:w-3/5 w-full md:h-12 h-10 outline-none border-2 md:p-2 p-1 rounded-lg' maxLength={10} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Pincode</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="pincode"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    pattern: /^\d{6}$/, // Only allow 6 digits
                                                    message: 'Please enter a valid 6-digit pincode!',
                                                }
                                            ]}
                                        >
                                            <Input type='pincode' placeholder='Pincode' name='pincode' maxLength={6} className='md:w-3/5 md:h-12 h-10 md:p-2 p-1 outline-none border-2 rounded-lg' />
                                        </Form.Item>
                                    </div>
                                </div>


                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Password</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                { max: 12, min: 6, message: 'Password size must be 6-12 characters' }
                                            ]}
                                        >
                                            <Input.Password placeholder='Enter password' name='password' className='md:w-3/5 w-full md:h-12 h-10 outline-none border-2 md:p-2 p-1 rounded-lg' />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Confirm Password</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="confirmpassword"
                                            rules={[
                                                { max: 12, min: 6, message: 'Password size must be 6-12 characters' }
                                            ]}
                                        >
                                            <Input.Password placeholder='Confirm password' name='confirmpassword' className='md:w-3/5 w-full md:h-12 h-10 outline-none border-2 p-2 rounded-lg' />
                                        </Form.Item>
                                    </div>
                                </div>


                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>State</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="userstate"
                                            className='md:w-3/5 w-full'
                                        >
                                            <Select
                                                className='bg-lightBlue border-2 md:h-12 h-10 rounded-lg'
                                                placeholder="Select State">
                                                {
                                                    states?.map((state) => (
                                                        <Select.Option value={state.stateId}>{state.stateName}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>


                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>City</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="city"
                                        >
                                            <Input placeholder='City' name='city' className='md:w-3/5 w-full md:h-12 h-10 outline-none border-2 md:p-2 p-1 rounded-lg' />
                                        </Form.Item>
                                    </div>
                                </div>


                                <div className='my-3'>
                                    <div className='my-2'>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Country</label><span className='text-red-500'>*</span></div>
                                        <Form.Item
                                            name="country"
                                        >
                                            <Input placeholder='Country' name='country' className='md:w-3/5 w-full md:h-12 h-10 outline-none border-2 p-2 rounded-lg' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='bg-white rounded-lg px-8 py-4 my-8'>
                        <div className='flex md:flex-row flex-col justify-between'>
                            <div className='leftArea md:w-2/4 w-screen'>
                                <h2 className='md:text-2xl text-lg font-bold'>Permission</h2>
                                <span className='text-textGray md:text-base text-sm'>Items that the account is allowed to edit</span>
                            </div>
                            <div className='rightArea w-2/4'>
                                <Form>
                                    <div className='my-3'>
                                        <div className='my-2 flex flex-col'>
                                            <label className='md:text-lg text-base font-medium md:font-semibold my-2'>Add product</label>
                                            <div className=' w-1/5 flex gap-6'>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.addProduct.allow ? 'bg-primaryColor text-white' : ''}`} ><input type='radio' id='allow' name='addProduct' className='w-full md:p-2 p-1' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Allow</label></div>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.addProduct.deny ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='deny' name='addProduct' className='w-full md:p-2 p-1' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Deny</label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-3'>
                                        <div className='my-2'>
                                            <label className='md:text-lg text-base font-medium md:font-semibold my-2'>Update product</label>
                                            <div className=' w-1/5 flex gap-6'>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.updateProduct.allow ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='allow' name='updateProduct' className='w-full md:p-2 p-1 ' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Allow</label></div>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.updateProduct.deny ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='deny' name='updateProduct' className='w-full md:p-2 p-1' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Deny</label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-3'>
                                        <div className='my-2'>
                                            <label className='md:text-lg text-base font-medium md:font-semibold my-2'>Delete product</label>
                                            <div className=' w-1/5 flex gap-6'>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.deleteProduct.allow ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='allow' name='deleteProduct' className='w-full md:p-2 p-1 ' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Allow</label></div>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.deleteProduct.deny ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='deny' name='deleteProduct' className='w-full md:p-2 p-1 ' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Deny</label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-3'>
                                        <div className='my-2'>
                                            <label className='md:text-lg text-base font-medium md:font-semibold my-2'>Apply discount</label>
                                            <div className=' w-1/5 flex gap-6'>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.discount.allow ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='allow' name='discount' className='w-full md:p-2 p-1 ' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Allow</label></div>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.discount.deny ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='deny' name='discount' className='w-full md:p-2 p-1 ' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Deny</label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-3'>
                                        <div className='my-2'>
                                            <label className='md:text-lg text-base font-medium md:font-semibold my-2'>Create coupon</label>
                                            <div className=' w-1/5 flex gap-6'>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.coupon.allow ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='allow' name='coupon' className='w-full md:p-2 p-1  ' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Allow</label></div>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.coupon.deny ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='deny' name='coupon' className='w-full md:p-2 p-1  ' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Deny</label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-3'>
                                        <div className='my-2'>
                                            <label className='md:text-lg text-base font-medium md:font-semibold my-2'>Order Cancellation</label>
                                            <div className=' w-1/5 flex gap-6'>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.coupon.allow ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='allow' name='coupon' className='w-full md:p-2 p-1 ' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Allow</label></div>
                                                <div className={`flex items-center gap-3 bg-gray-300 text-textGray rounded-3xl md:px-3.5 px-2.5 md:py-1.5 py-1 ${permissions.coupon.deny ? 'bg-primaryColor text-white' : ''}`}><input type='radio' id='deny' name='coupon' className='w-full md:p-2 p-1' onClick={e => handleChange(e)} /><label className='md:text-lg text-sm font-medium'>Deny</label></div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className='flex md:flex-row flex-col md:items-center items-end justify-end gap-3 mt-10 mb-5'>
                            <button className='md:min-w-32 w-[6rem] md:w-1/5 md:p-3 p-1 bg-primaryColor text-white rounded-lg font-semibold' onClick={handleUserSave}>Save</button>
                            <button className='md:min-w-32 w-[6rem] md:w-1/5 md:p-3 p-1 border border-dangerColor text-dangerColor rounded-lg font-semibold custom-button-shadow hover:text-white' onClick={handleCancelClick}>Cancel</button>
                        </div>
                    </div>

                </div>
            </div>
        </Layouts >
    )
}

export default AddUser;