import React, { useEffect, useRef, useState } from 'react';
import Layouts from '../../../Layouts';
import { BsCloudUpload } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';
import { LuCircleDotDashed } from 'react-icons/lu';
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import EditModal from './EditModal';
import { Form, Input, Select, message } from 'antd';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { encodeBase64File } from "../../../../utils/encodeBase64"
import { addProduct, editProduct, getPackageDetails } from '../../../../functions/products';
import { Option } from 'antd/es/mentions';
import { secureStorage } from '../../../../functions/isAuth';
import { IoMdCloseCircle } from 'react-icons/io';
// import uniqid from 'uniqid';

function AddProduct() {

    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [currHowtoUse, setCurrHowtoUse] = useState('');
    const [howToUse, setHowToUse] = useState([]);
    const [currIngredients, setCurrIngredients] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [currBenefits, setCurrBenefits] = useState('');
    const [benefits, setBenefits] = useState([]);
    const [howToUseModal, setHowToUseModal] = useState();
    const [ingredientsModal, setIngredientsModal] = useState();
    const [benefitsModal, setBenefitsModal] = useState();
    const navigate = useNavigate();
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [uploadedImage, setUploadedImage] = useState();
    const [productData, setProductData] = useState();
    const [packages, setPackages] = useState();
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [isEditOn, setIsEditOn] = useState(false);
    // const [itemData,setItemData] = useState();
    const formRef = useRef();
    const { state } = useLocation();
    const [form] = Form.useForm();
    // const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = secureStorage.getItem('access_token');
        if (!token) {
            navigate('/');
        }
        else {
            getPackages();
        }
    }, []);


    useEffect(() => {
        setBenefits(state?.product ? state?.product?.productBenfits : []);
        setIngredients(state?.product ? state?.product?.productIngredients : []);
        setHowToUse(state?.product ? state?.product?.productToUse : []);
        if (state?.product !== undefined && state?.product !== null) {
            console.log("hi", state.product);
            setIsEditOn(true);
        }
    }, [state?.product])

    const getPackages = async () => {
        const response = await getPackageDetails();
        setPackages(response.data);
    }

    const handleCancelClick = () => {
        navigate('/productmaster');
    }

    const handleSubCategoryChange = (value) => {
        setSubCategory(value);
    }
    const handleUploadDelete = () => {
        setUploadedImage();
    };

    const categoryChange = (value) => {
        setCategory(value);
    }

    const handleSizes = (value) => {
        setSelectedPackages(value);
    }

    const handleModal = (type) => {
        if (type === 'ingredients') {
            setIngredientsModal(prevState => !prevState);
            setHowToUseModal(false);
            setBenefitsModal(false);
        }
        else if (type === 'howToUse') {
            setHowToUseModal(prevState => !prevState);
            setIngredientsModal(false);
            setBenefitsModal(false);
        }
        else if (type === 'benefits') {
            setBenefitsModal(prevState => !prevState);
            setHowToUseModal(false);
            setIngredientsModal(false);
        }
    }

    const handleFieldValueChange = (itemIndex, InputValue, type) => {
        if (type === "howToUse") {
            const howtouseArr = howToUse;
            howtouseArr[itemIndex].description = InputValue;
            setHowToUse(howtouseArr);

        }
        else if (type === "benefits") {
            const benefitsArr = benefits;
            benefitsArr[itemIndex].description = InputValue;
            setBenefits(benefitsArr);

        }
        else if (type === "ingredients") {
            const ingredientsArr = ingredients;
            ingredientsArr[itemIndex].ingredientName = InputValue;
            setIngredients(ingredientsArr);
        }
    }

    const handleHowToUse = () => {
        const currDate = new Date().toISOString();
        const date = currDate.split('T')[0];
        const time = currDate.split('T')[1].slice(0, 8);
        const constructedTime = date + " " + time;
        let item = {
            createdBy: "VAD",
            createdDate: constructedTime,
            description: currHowtoUse,
            modifiedBy: "VAD",
            modifiedDate: constructedTime,
            sno: howToUse?.length + 1,
            status: 1
        }
        setHowToUse(prevState => [...prevState, item]);
        setCurrHowtoUse('')
    }

    const handleIngredients = () => {

        const currDate = new Date().toISOString();
        const date = currDate.split('T')[0];
        const time = currDate.split('T')[1].slice(0, 8);
        const constructedTime = date + " " + time;

        let item = {
            createdBy: "VAD",
            createdDate: constructedTime,
            ingredientName: currIngredients,
            modifiedBy: "VAD",
            modifiedDate: constructedTime,
            sno: ingredients.length + 1,
            status: 1
        }
        setIngredients(prevState => [...prevState, item]);
        setCurrIngredients('')
    }

    const handleBenefits = () => {

        const currDate = new Date().toISOString();
        const date = currDate.split('T')[0];
        const time = currDate.split('T')[1].slice(0, 8);
        const constructedTime = date + " " + time;

        let item = {
            benfitDetails: currBenefits,
            createdBy: "VAD",
            createdDate: constructedTime,
            description: currBenefits,
            modifiedBy: "VAD",
            modifiedDate: constructedTime,
            sno: benefits.length + 1,
            status: 1
        }
        setBenefits(prevState => [...prevState, item]);
        setCurrBenefits('')
    }

    const handleImage = (e) => {
        setUploadedImage(e.target.files[0]);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        let payload = {};
        const currDate = new Date().toISOString();
        const date = currDate.split('T')[0];
        const time = currDate.split('T')[1].slice(0, 8);
        const constructedTime = date + " " + time;

        const { productname, hsncode, packagesizes, category, subcategory, description, gst } = formRef.current.getFieldValue();
        console.log(formRef.current.getFieldValue());

        if (state?.product?.name === undefined || state?.product?.name === "" || state?.product?.name === null) {
            if (productname === "" || productname === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product?.hsnCode === undefined || state?.product?.hsnCode === "" || state?.product?.hsnCode === null) {
            if (hsncode === "" || hsncode === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product?.productCategoryCode === undefined || state?.product?.productCategoryCode === "" || state?.product?.productCategoryCode === null) {
            if (category === "" || category === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product?.productSubCategoryCode === undefined || state?.product?.productSubCategoryCode === "" || state?.product?.productSubCategoryCode === null) {
            if (subCategory === "" || subCategory === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product?.description === undefined || state?.product?.description === "" || state?.product?.description === null) {
            if (description === "" || description === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product?.imageName === undefined || state?.product?.imageName === "" || state?.product?.imageName === null) {
            if (uploadedImage === "" || uploadedImage === undefined || uploadedImage === null) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product?.productPackageDetails?.length === undefined || state?.product?.productPackageDetails?.length === 0 || state?.product?.productPackageDetails === null) {
            if (packagesizes.length === 0 || packagesizes.length === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product?.gst === undefined || state?.product?.gst === '' || state?.product?.gst === null) {
            if (gst === '' || gst === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }


        // if (!productname && !hsncode && !category && !subCategory && !description && howToUse.length === 0 && ingredients.length === 0 && benefits.length === 0 && !image && selectedPackages.length === 0) {
        //     message.error("Please fill in all fields");
        //     return;
        // }


        console.log(isEditOn);
        console.log("hi", state);
        if (state?.product !== null && state?.product !== undefined && state?.product !== "") {
            let packageSizes;
            if (packagesizes?.length !== 0) {
                packageSizes = packagesizes?.map((size) => {
                    return {
                        createdBy: "VAD",
                        createdDate: constructedTime,
                        modifiedBy: "VAD",
                        modifiedDate: constructedTime,
                        packageSize: size,
                        productCode: state?.product?.code,
                        status: 1
                    }
                });
            }


            payload = {
                ...state?.product,
                code: state?.product?.code,
                hsnCode: hsncode ? hsncode : state?.product?.hsnCode,
                gst: gst ? gst : state?.product?.gst,
                name: productname ? productname : state?.product?.name,
                description: description ? description : state?.product?.description,
                productCategoryCode: category ? category : state?.product?.productCategoryCode,
                productSubCategoryCode: subCategory ? subCategory : state?.product?.productSubCategoryCode,
                imageName: uploadedImage ? uploadedImage.name : state?.product?.imageName,
                createdBy: "VAD",
                createdDate: state?.product.createdDate,
                modifiedBy: "VAD",
                modifiedDate: constructedTime,
                status: 1,
                productBenfits: benefits,
                productIngredients: ingredients,
                productToUse: howToUse,
                productPackageDetails: packageSizes ? packageSizes : state?.product?.productPackageDetails
            }
            if (state?.product.imageName === undefined || state?.product.imageName === "" || state?.product.imageName === null) {
                if (uploadedImage === undefined || uploadedImage === "") {
                    message.error("Please upload a Image");
                    return;
                }
                else {
                    console.log(uploadedImage);
                    const res = await encodeBase64File(uploadedImage);
                    console.log(res);
                    if (uploadedImage.type.includes("pdf")) {
                        payload.imageType = "pdf";
                        payload.image = res;
                    } else if (uploadedImage.type.includes("png")) {
                        payload.imageType = "png";
                        payload.image = res;
                    } else if (uploadedImage.type.includes("jpg")) {
                        payload.imageType = "jpg";
                        payload.image = res;
                    } else if (uploadedImage.type.includes("jpeg")) {
                        payload.imageType = "jpeg";
                        payload.image = res;
                    }
                }
            }
            else {
                if (uploadedImage) {
                    const res = await encodeBase64File(uploadedImage);
                    console.log(res);
                    if (uploadedImage.type.includes("pdf")) {
                        payload.imageType = "pdf";
                        payload.image = res;
                    } else if (uploadedImage.type.includes("png")) {
                        payload.imageType = "png";
                        payload.image = res;
                    } else if (uploadedImage.type.includes("jpg")) {
                        payload.imageType = "jpg";
                        payload.image = res;
                    } else if (uploadedImage.type.includes("jpeg")) {
                        payload.imageType = "jpeg";
                        payload.image = res;
                    }
                }
            }

            console.log(payload);
            await editProduct(payload, state?.product.id);
            navigate('/productmaster')
        }
        else {

            let packageSizes = packagesizes.map((size) => {
                return {
                    createdBy: "VAD",
                    createdDate: constructedTime,
                    modifiedBy: "VAD",
                    modifiedDate: constructedTime,
                    packageSize: size,
                    productCode: `${state.productsLength !== null && state.productsLength + 1}`,
                    status: 1
                }
            })

            payload = {
                code: `${state.productsLength !== null && state.productsLength + 1}`,
                hsnCode: hsncode,
                gst,
                name: productname,
                description,
                productCategoryCode: category,
                productSubCategoryCode: subCategory,
                imageName: uploadedImage.name,
                createdBy: "VAD",
                createdDate: constructedTime,
                modifiedBy: "VAD",
                modifiedDate: constructedTime,
                status: 1,
                productBenfits: benefits,
                productIngredients: ingredients,
                productToUse: howToUse,
                productPackageDetails: packageSizes
            }

            if (uploadedImage) {
                console.log(uploadedImage);
                const res = await encodeBase64File(uploadedImage);
                console.log(res);
                if (uploadedImage.type.includes("pdf")) {
                    payload.imageType = "pdf";
                    payload.image = res;
                } else if (uploadedImage.type.includes("png")) {
                    payload.imageType = "png";
                    payload.image = res;
                } else if (uploadedImage.type.includes("jpg")) {
                    payload.imageType = "jpg";
                    payload.image = res;
                } else if (uploadedImage.type.includes("jpeg")) {
                    payload.imageType = "jpeg";
                    payload.image = res;
                }
            } else {
                message.error("Please upload a Image");
                return;
            }

            await addProduct(payload);
            navigate('/productmaster')

        }




        console.log(payload)
        // await addProduct(payload);
        // navigate('/product')
    }

    const handleRemove = (idx, type) => {
        if (type === 'ingredients') {
            const ingredientArr = ingredients;
            ingredientArr.splice(idx, 1);
            setIngredients(prevState => [...ingredientArr]);
        }
        else if (type === 'howToUse') {
            const howtoUseArr = howToUse;
            howtoUseArr.splice(idx, 1);
            setHowToUse(prevState => [...howtoUseArr]);
        }
        else if (type === 'benefits') {
            const benefitsArr = benefits;
            benefitsArr.splice(idx, 1);
            setBenefits(prevState => [...benefitsArr]);
        }
    }

    // if (loading) {
    //     return <div>Loading...</div>;
    // }


    return (
        <Layouts>
            <div className='flex'>
                <div className='w-screen md:w-full'>
                    <div className='md:flex items-center justify-between md:mb-8 mb-4'>
                        <h1 className='md:text-2xl text-xl font-bold'>{state?.product ? "Edit Product" : "New Product"}</h1>
                    </div>
                    <Form ref={formRef}
                        initialValues={{
                            productname: state?.product?.name,
                            category: state?.product?.productCategoryCode,
                            subcategory: state?.product?.productSubCategoryCode,
                            hsncode: state?.product?.hsncode,
                            package: state?.product?.package,
                            description: state?.product?.description
                        }}
                    >

                        {/* {console.log(itemData)} */}
                        <div className='md:w-full md:flex'>
                            <div className='leftForm bg-white rounded-xl md:w-2/4 md:p-6 p-3 mx-1'>
                                <div className='flex md:flex-row flex-col items-center md:justify-between'>
                                    <div className='md:w-[60%] w-full md:my-2 my-0.5'>
                                        <div>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>Product Name</label><span className='text-dangerColor'>*</span></div>
                                            <Form.Item name="productname" rules={[{ max: 20, message: 'Name is too long. Only 20 characters allowed.' }]}>
                                                <Input placeholder='Enter the Product name' className='md:w-full w-[80%]  outline-none border-borderColor border-2 md:p-2 p-1 rounded-lg' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='md:w-[30%] w-full md:my-2 my-0.5'>
                                        <div>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>HSN Code</label><span className='text-dangerColor'>*</span></div>
                                            <Form.Item name="hsncode">
                                                <Input placeholder='Enter the HSN Code' className='md:w-full w-[80%] outline-none border-2 border-borderColor md:p-2 p-1 rounded-lg' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex md:flex-row flex-col items-center md:justify-between'>
                                    <div className='md:w-[45%] w-full md:my-2 my-0.5'>
                                        <div className='md:w-full w-[80%]'>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>GST %</label><span className='text-dangerColor'>*</span></div>
                                            <Form.Item name="gst">
                                                <Select
                                                    placeholder="Select GST Percentage"
                                                    className='md:w-full md:h-[2.5rem] h-[2.25rem]'
                                                >
                                                    <Option value="5%">5%</Option>
                                                    <Option value="12%">12%</Option>
                                                    <Option value="18%">18%</Option>
                                                    <Option value="28%">28%</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='md:w-[45%] w-full md:my-2 my-0.5'>
                                        <div className='md:w-full w-[80%]'>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>Select Package Size</label><span className='text-dangerColor'>*</span></div>
                                            <Form.Item name="packagesizes">
                                                <Select
                                                    mode="multiple"
                                                    onChange={handleSizes}
                                                    placeholder="Select Package sizes"
                                                    className='md:w-full md:h-[2.5rem] h-[2.25rem]'
                                                >
                                                    {
                                                        packages?.map((size) => (
                                                            <Option key={size.id} value={size.name}>{size.name}</Option>
                                                        ))
                                                    }

                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full flex md:flex-row flex-col items-center md:justify-between'>

                                    <div className='md:w-[45%] w-full md:my-2 my-0.5'>
                                        <div className='md:w-full w-[80%]'>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>Category</label><span className='text-dangerColor'>*</span></div>
                                            <Form.Item name="category">
                                                <Select
                                                    onChange={categoryChange}
                                                    placeholder="Select a Category"
                                                    className='md:w-full md:h-[2.5rem] h-[2.25rem]'>
                                                    <Select.Option value="Kashayam">Kashayam</Select.Option>
                                                    <Select.Option value="Churnams">Churnams</Select.Option>
                                                    <Select.Option value="Tailam">Tailam</Select.Option>
                                                    <Select.Option value="Ghrithms">Ghrithms</Select.Option>
                                                    <Select.Option value="Lehams">Lehams</Select.Option>
                                                    {/* <Select.Option value="Vati">Vati</Select.Option> */}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='md:w-[45%] w-full md:my-2 my-0.5'>
                                        <div className='md:w-full w-[80%]'>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>Sub Category</label><span className='text-dangerColor'>*</span></div>
                                            <Form.Item name="subcategory">
                                                <Select
                                                    onChange={handleSubCategoryChange}
                                                    placeholder="Select a Sub Category"
                                                    className='md:w-full md:h-[2.5rem] h-[2.25rem]'>
                                                    <Select.Option value="Herbal Supplements and Medicines">Herbal Supplements and Medicines</Select.Option>
                                                    <Select.Option value="Ayurvedic Skincare">Ayurvedic Skincare</Select.Option>
                                                    <Select.Option value="Hair Care">Hair Care</Select.Option>
                                                    <Select.Option value="Massage Oils and Body Care">Massage Oils and Body Care</Select.Option>
                                                    <Select.Option value="Herbal Teas and Tonics">Herbal Teas and Tonics</Select.Option>
                                                    <Select.Option value="Ayurvedic Cosmetics">Ayurvedic Cosmetics</Select.Option>
                                                    <Select.Option value="Ayurvedic Health and Wellness Kits">Ayurvedic Health and Wellness Kits</Select.Option>
                                                    <Select.Option value="Ayurvedic Dietary Supplements">Ayurvedic Dietary Supplements</Select.Option>
                                                    <Select.Option value="Herbal Powders and Pastes">Herbal Powders and Pastes</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full md:my-2 my-0.5'>
                                    <div>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Description</label><span className='text-dangerColor'>*</span></div>
                                        <div className='w-full flex gap-3 items-center'>
                                            <Form.Item name="description" className='w-[inherit]'>
                                                <textarea type='text' className='w-full minHeight outline-none border-2 border-borderColor p-2 rounded-lg' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:block hidden my-2 w-full'>
                                    <div>
                                        <div className='my-2'><label className='text-lg font-medium'>How to use</label><span className='text-red-500'>*</span></div>
                                        <div className='w-full flex gap-3 items-center'>
                                            <input type='text' value={currHowtoUse} className='w-[85%] outline-none border-2 border-borderColor p-2 rounded-lg' onChange={(e) => setCurrHowtoUse(e.target.value)} />
                                            <button onClick={handleHowToUse} className='bg-primaryColor px-3 py-2 text-white rounded-lg font-semibold'>+</button>
                                            <MdOutlineRemoveRedEye className='text-2xl text-textGray' onClick={() => handleModal('howToUse')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='rightForm relative md:mt-0 mt-6 bg-white rounded-xl md:w-2/4 p-6 mx-1'>
                                <div className='md:hidden block md:my-2 my-0.5 w-full'>
                                    <div>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>How to use</label><span className='text-red-500'>*</span></div>
                                        <div className='w-full flex gap-3 items-center'>
                                            <input type='text' value={currHowtoUse} className='w-[85%] outline-none border-2 border-borderColor md:p-2 p-1 rounded-lg' onChange={(e) => setCurrHowtoUse(e.target.value)} />
                                            <button onClick={handleHowToUse} className='bg-primaryColor md:px-3 px-2 md:py-2 py-1 text-white rounded-lg font-semibold'>+</button>
                                            <MdOutlineRemoveRedEye className='md:text-2xl text-xl text-textGray' onClick={() => handleModal('howToUse')} />
                                        </div>
                                    </div>
                                </div>
                                <div className='md:my-2 my-0.5 w-full'>
                                    <div>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Ingredients</label><span className='text-dangerColor'>*</span></div>
                                        <div className='w-full flex gap-3 items-center'>
                                            <input type='text' value={currIngredients} className='w-[85%] outline-none border-2 border-borderColor md:p-2 p-1 rounded-lg' onChange={(e) => setCurrIngredients(e.target.value)} />
                                            <button onClick={handleIngredients} className='bg-primaryColor md:px-3 px-2 md:py-2 py-1 text-white rounded-lg font-semibold'>+</button>
                                            <MdOutlineRemoveRedEye className='md:text-2xl text-xl text-textGray' onClick={() => handleModal('ingredients')} />
                                        </div>
                                    </div>
                                </div>
                                <div className='md:my-2 my-0.5 w-full'>
                                    <div>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Benefits</label><span className='text-dangerColor'>*</span></div>
                                        <div className='w-full flex gap-3 items-center'>
                                            <input type='text' value={currBenefits} className='w-[85%] outline-none border-2 border-borderColor p-2 rounded-lg' onChange={(e) => setCurrBenefits(e.target.value)} />
                                            <button onClick={handleBenefits} className='bg-primaryColor md:px-3 px-2 md:py-2 py-1 text-white rounded-lg font-semibold'>+</button>
                                            <MdOutlineRemoveRedEye className='md:text-2xl text-xl text-textGray' onClick={() => handleModal('benefits')} />
                                        </div>
                                    </div>
                                </div>
                                <Form.Item
                                    name="upload"
                                >
                                    <div className='imageArea my-5 '>
                                        <div className='my-2 flex flex-col'>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>Product Image</label><span className='text-dangerColor'>*</span></div>
                                            {!uploadedImage && <><div className='flex flex-col justify-center relative border-2 border-borderColor p-2 md:h-64 h-40 md:w-64 w-40 rounded-lg'>
                                                <BsCloudUpload className='text-[3.5rem] relative md:left-[6rem] left-[3rem]  text-primaryColor' />
                                                <input disabled={uploadedImage} type='file' className='w-[inherit] outline-none ml-[2.75rem] absolute left-8 h-16 opacity-0' onChange={handleImage} />
                                            </div>
                                                <p className='text-textGray md:text-base text-xs md:my-0 my-2'>Image Size must be less than or equal to 1 MB.</p>
                                            </>
                                            }
                                        </div>
                                        {uploadedImage && (

                                            // <div className="flex justify-between top-[23%] left-[3%] w-[90%]">
                                            //     <p>{uploadedImage.name}</p>
                                            //     <DeleteOutlined
                                            //         className="text-danger"
                                            //         role="button"
                                            //         onClick={handleUploadDelete}
                                            //     />
                                            // </div>
                                            <>
                                                <div className='flex flex-col justify-center relative border-2 border-borderColor p-2 md:h-64 h-40 md:w-64 w-40 rounded-lg'>
                                                    <div className='flex justify-end text-3xl cursor-pointer py-1' onClick={handleUploadDelete}>
                                                        <IoMdCloseCircle />
                                                    </div>
                                                    <div className='flex justify-center items-center'>
                                                        <img src={URL.createObjectURL(uploadedImage)} alt={uploadedImage.name} className='w-[80%] h-[80%] object-cover' />
                                                    </div>
                                                </div>
                                            </>

                                        )}
                                    </div>
                                </Form.Item>
                                <div className='flex items-center md:justify-start justify-center w-full gap-3 absolute bottom-5'>
                                    <button type='submit' className='w-[6rem] md:w-2/5 md:p-3 p-1 bg-primaryColor text-white rounded-lg md:font-semibold font-medium' onClick={handleSubmit}>{state?.product ? "Edit Product" : "Add product"}</button>
                                    <button className='w-[6rem] md:w-2/5 md:p-3 p-1 border border-dangerColor text-dangerColor rounded-lg font-semibold custom-button-shadow hover:text-white' onClick={handleCancelClick}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <>
                {howToUseModal && <EditModal selectedItem={howToUse} handleFieldValueChange={handleFieldValueChange} modalState={howToUseModal} handleModal={handleModal} type={"howToUse"} />}
            </>
            <>
                {benefitsModal && <EditModal selectedItem={benefits} handleFieldValueChange={handleFieldValueChange} modalState={benefitsModal} handleModal={handleModal} type={"benefits"} />}
            </>
            <>
                {ingredientsModal && <EditModal selectedItem={ingredients} handleFieldValueChange={handleFieldValueChange} modalState={ingredientsModal} handleModal={handleModal} type={"ingredients"} />}
            </>


        </Layouts >
    )
}

export default AddProduct
