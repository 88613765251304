import { message } from "antd"
import axios from "axios";
import { debounce } from "lodash";
import { secureStorage } from "./isAuth";
const baseUrl = process.env.REACT_APP_BASE_URL;
const token = secureStorage.getItem('access_token');

export const addContent = async (payload) => {

    for (let i = 0; i < payload.length; i++) {
        try {
            const response = await axios.post(`${baseUrl}/headerScroll/`, payload[i], {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log("response from content ", response);
        }
        catch (e) {
            message.error('Error Occurred');
        }
    }
}


export const addTestimonialContent = async (payload) => {
    try{
        const response = await axios.post(`${baseUrl}/testimonials/`,payload,{headers:{
            Authorization:`Bearer ${token}`
        }});
        console.log("response testimonial ",response);
        message.success("Testimonials added successfully");

    }catch(e){
        message.error("Error occurred");
    }
}

export const getScrollContents = async() => {
    try{
        const response = await axios.get(`${baseUrl}/headerScroll/list/filter`,{headers:{
            Authorization:`Bearer ${token}`
        }});
        return response.data;
    }catch(e){
        message.error("Error occurred.Please visit after sometime.");
    }
}

export const getTestimonials = async () => {
    try{
        const response = await axios.get(`${baseUrl}/testimonials/list/filter`,{headers:{
            Authorization:`Bearer ${token}`
        }});
        return response.data;
    }catch(e){
        message.error("Error occurred.Please visit after sometime.");
    }
}