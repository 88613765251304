import React, { useEffect, useState } from 'react'
import Layouts from '../../Layouts'
import { IoSearchOutline } from "react-icons/io5";
import { data } from '../../../data/data';
import { useNavigate } from 'react-router-dom';
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import PreviewModal from '../../Shared/Modal';
import { getProducts } from '../../../functions/products';
import Table from './ProductMasterTable';
import ShimmerEffect from '../../Shared/ShimmerEffect';
import { Select } from 'antd';
import { hospitalData } from '../../../data/FacilityData';
import AddImages from "../Product/AddImages";
import { Modal } from "antd";
import { secureStorage } from '../../../functions/isAuth';

function ProductMaster() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [products, setProducts] = useState();
  const [isLoading,setIsLoading] = useState(true);
  const [currSelectedProduct, setCurrSelectedProduct] = useState();
  const navigate = useNavigate();
  const arr = new Array(5).fill(0);

  const getAllProducts = async () => {
    const response = await getProducts();
    setProducts(response.data);
    if(response.data){
      setIsLoading(false);
    }
    console.log(response.data);
  }

  useEffect(() => {
    const token = secureStorage.getItem('access_token');
    if (!token) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    getAllProducts();
  }, []);

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  }

  const handleAddProduct = () => {
    navigate('/updateProduct', { state: { productsLength: products.length } });
  }

  const handleEditClick = (item) => {
    setCurrSelectedProduct(item);
    navigate('/updateProduct', { state: { product: item } });
  };

  const handleImageIconClick = (item) => {
    setCurrSelectedProduct(item);
    setIsEditModalOpen(true);
  }

  const handleModal = (actionType, selectedObj) => {
    console.log(selectedObj);
    setIsModalOpen(prevState => !prevState);
    if (actionType === 'view') {
      setSelectedItem(selectedObj)
    }
  }
  console.log(products);


  return (
    <>
      <Layouts>
        <div>
          <div className='md:flex items-center justify-between md:mb-8 mb-4'>
            <h1 className='md:text-2xl text-xl font-bold'>Product Master</h1>
          </div>
          <div className='flex '>
            <div className={`bg-white rounded-xl p-5 ${products ? 'h-max' : 'h-screen'} md:w-full flex-1`}>
              <div>
                <div className='flex gap-2 items-center text-textGray md:text-sm text-xs'><MdOutlineTipsAndUpdates className='md:text-base text-sm text-primaryColor' />Tip : search by Product Name, which you can rely on to find the extact product you need.</div>
                <div className='flex justify-between items-center py-6'>
                  <div className='w-full flex gap-3 items-center'>
                    <Select
                      className='w-[25%] md:h-[2.5rem] h-[2rem]'
                      placeholder="Select a Facility"
                      options={[{ Facility: "Venkatarama Ayurveda Dispensary" }].map((item) => {
                        return {
                          label: item.Facility,
                          value: item.Facility,
                        };
                      })}>
                    </Select>
                    <div className='flex items-center justify-between border-borderColor border rounded-lg md:py-2 py-1 md:px-5 px-3 '>
                      <input type='text' placeholder='Search here...' className='outline-none' />
                      <IoSearchOutline className="md:text-xl text-lg" />
                    </div>
                  </div>
                  <button className='md:py-[7px] py-[3px] md:w-52 w-36 md:text-base text-sm font-semibold rounded-lg border border-primaryColor text-primaryColor' onClick={handleAddProduct}> <span className='font-normal md:text-lg text-base pr-3 '>+</span> Add New</button>
                </div>
              </div>
              {
                isLoading && !products?
                  (<div className='flex flex-col gap-3 items-center'>
                    {
                      arr.map((idx) => (<ShimmerEffect key={idx} />))
                    }
                  </div>) :
                  (<Table data={products} handleModal={handleModal} handleImageIconClick={handleImageIconClick} handleEditClick={handleEditClick} />)

              }
            </div>
          </div>

        </div>
      </Layouts>
      {isModalOpen && <PreviewModal selectedItem={selectedItem} isModalOpen handleModal={handleModal} />}
      {isEditModalOpen &&
        <Modal open={isEditModalOpen} footer={[]} onCancel={handleEditCancel} width={'40%'}>
          <AddImages product={currSelectedProduct} productsLength={products.length} handleCancel={handleEditCancel} />
        </Modal>
      }

    </>
  )
}

export default ProductMaster;
