import './App.css';
import {Routes,Route} from 'react-router-dom'
import Login from "./Components/Pages/LoginPage/Login";
import Register from './Components/Pages/LoginPage/Register';
import Dashboard from "./Components/Pages/Dashboard";
import HospitalList from "./Components/Pages/Facilities/FacilityList";
import AddLocation from "./Components/Pages/Facilities/AddNewLocation";
import EditFacility from './Components/Pages/Facilities/EditLocation';
import LocationsList from "./Components/Pages/Facilities/LocationsList";
import ProductMaster from "./Components/Pages/Product";
import AddProduct from "./Components/Pages/Product/AddProduct";
import EditProduct from "./Components/Pages/Product/EditProduct";
import Customers from "./Components/Pages/Customers";
import CustomerProfile from "./Components/Pages/Customers/CustomerProfile";
import Users from "./Components/Pages/Users";
import Orders from "./Components/Pages/Orders";
import AddUser from "./Components/Pages/Users/AddUser";
import EditUser from "./Components/Pages/Users/EditUser";
import ForgotPassword from "./Components/Pages/LoginPage/ForgotPassword";
import ResetPassword from "./Components/Pages/LoginPage/ResetPassword";
import OrderDetails from "./Components/Pages/Orders/OrderDetails";
import OrderTracking from './Components/Pages/Orders/OrderTracking';
import ProductList from './Components/Pages/Product/ProductList';
import UpdateProductList from './Components/Pages/Product/ProductList/updateProductList';
import Reports from "./Components/Pages/Reports";
import AddImages from './Components/Pages/Product/AddImages';
import ShippingCharges from "./Components/Pages/Product/ProductShipping";
import ContentPage from './Components/Pages/Content';
import ProductAvailability from './Components/Pages/Product/ProductAvailability';


function App() {


  return (
    <Routes>
        <Route path='/' element={<Login/>} />
        <Route path='/register' element={<Register/>}/>
        <Route path='/dashboard' element={<Dashboard/>} />
        <Route path='/facilityList' element={<HospitalList/>} />
        <Route path='/locationList' element={<LocationsList/>} />
        <Route path='/addLocation' element={<AddLocation/>}/>
        <Route path='/editLocation' element={<EditFacility/>}/>
        <Route path='/productmaster' element={<ProductMaster/>} />
        <Route path='/updateProduct' element={<AddProduct/>}/>
        <Route path='/editProduct' element={<EditProduct/>}/>
        <Route path='/productlist' element={<ProductList/>}/>
        <Route path='/productAvailability' element={<ProductAvailability/>}/>
        <Route path='/shippingCharges' element={<ShippingCharges/>}/>
        <Route path='/updateproductlist' element={<UpdateProductList/>}/>
        <Route path='/customers' element={<Customers/>} />
        <Route path="/customer/:customerId" element={<CustomerProfile/>} />
        <Route path='/users' element={<Users/>} />
        <Route path='/updateUser' element={<AddUser/>}/>
        <Route path='/editUser' element={<EditUser/>} />
        <Route path='/orders' element={<Orders/>} />
        <Route path="/forgotPassword" element={<ForgotPassword/>}/>
        <Route path="/resetPassword" element={<ResetPassword/>}/>
        <Route path='/orderDetails' element={<OrderDetails/>}/>
        <Route path='/orderTracking' element={<OrderTracking/>}/>
        <Route path='/reports' element={<Reports/>}/>
        <Route path='/addImage' element={<AddImages/>}/>
        <Route path='/headerscroll' element={<ContentPage/>}/>
     </Routes>
  );
}

export default App;
