import React, { useEffect, useState } from 'react';
import Layouts from '../../Layouts';
import { orderData } from '../../../data/orderData.js'
import { IoSearchOutline } from 'react-icons/io5';
import Table from './OrderTable.jsx';
import Modal from '../../Shared/Modal.jsx';
import { useNavigate } from 'react-router-dom';
import ShimmerEffect from '../../Shared/ShimmerEffect.jsx'
import { secureStorage } from '../../../functions/isAuth.js';


function Orders() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();
    const arr = new Array(5).fill(0);

    const handleModal = (actionType, selectedObj) => {
        setIsModalOpen(prevState => !prevState);
        if (actionType === 'view') {
            setSelectedItem(selectedObj)
        }
    }

    useEffect(() => {
        const token = secureStorage.getItem('access_token');
        if (!token) {
            navigate('/');
        }
    }, []);

    return (
        <>
            <Layouts>
                <div>
                    <div className='md:flex items-center justify-between md:mb-8 mb-4'>
                        <h1 className='md:text-2xl text-xl font-bold'>All Orders</h1>
                    </div>
                    <div className='flex'>
                        <div className={`bg-white rounded-xl p-5 ${orderData ? 'h-max' : 'h-screen'} md:w-full flex-1`}>
                            <div>
                                <div className='flex justify-between items-center py-6'>
                                    <div className='flex gap-3 items-center w-full'>
                                        <div className='md:w-[30%] w-[50%] flex items-center justify-between border border-borderColor rounded-lg md:py-2 py-1 md:px-5 px-3 '>
                                            <input type='text' placeholder='Search here...' className='md:w-[95%] w-[65%] outline-none md:placeholder:text-base placeholder:text-sm' />
                                            <IoSearchOutline className="md:text-xl text-sm" />
                                        </div>
                                    </div>
                                    <button className='md:py-[7px] py-[3px] md:w-52 w-36 md:text-base text-sm font-semibold rounded-lg border border-primaryColor text-primaryColor'> <span className='font-normal md:text-lg text-base pr-3'>+</span> Add new</button>
                                </div>
                            </div>
                            {orderData ?
                                (<Table data={orderData} handleModal={handleModal} />) :
                                (<div className='flex flex-col gap-3 items-center'>
                                    {
                                        arr.map((idx) => (<ShimmerEffect key={idx} />))
                                    }
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </Layouts>
            {isModalOpen && <Modal selectedItem={selectedItem} handleModal={handleModal} />}
        </>
    )
}

export default Orders
