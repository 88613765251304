import React, { useEffect, useRef, useState } from 'react'
import Layouts from '../../Layouts';
import { Form, Input, InputNumber, Select, Upload, message } from 'antd';
import { GoNorthStar } from "react-icons/go";
import { statesList } from '../../../functions/products';
import TextArea from 'antd/es/input/TextArea';
// import { BsCloudUpload } from 'react-icons/bs';
import { FaUser } from "react-icons/fa";
import { IoMdCloseCircle } from 'react-icons/io';
import { MdDelete } from "react-icons/md";
import { addContent, addTestimonialContent, debouncedAddContent, getScrollContents, getTestimonials } from '../../../functions/contents';
import { encodeBase64File } from '../../../utils/encodeBase64';
import ContentTable from './ContentTable';
import { secureStorage } from '../../../functions/isAuth';
import { useNavigate } from 'react-router-dom';


function ContentPage() {
    const formRef = useRef();
    const [contents, setContents] = useState([]);
    const [contentType, setContentType] = useState('headerscroll');
    const [states, setStates] = useState([]);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [headerScrollContents, setHeaderScrollContents] = useState([]);
    const [testimonialContents, setTestimonialContents] = useState([]);
    const navigate = useNavigate();



    useEffect(() => {
        const token = secureStorage.getItem('access_token');
        if (!token) {
            navigate('/');
        }
        else {
            getAllStates();
            getHeaderContents();
        }
    }, []);

    const getHeaderContents = async () => {
        const response = await getScrollContents();
        console.log(response);
        if (response) {
            setHeaderScrollContents(response);
        }
    }

    const getTestimonialContents = async () => {
        const response = await getTestimonials();
        if (response) {
            setTestimonialContents(response);
        }
    }

    const handleImage = (e) => {
        console.log(e.target.files[0]);
        setUploadedImage(e.target.files[0]);
    }

    const getAllStates = async () => {
        const response = await statesList();
        setStates(response?.data);
    }

    const handleAddedItem = () => {
        const { contentText } = formRef.current.getFieldValue();
        setContents(prevState => [...prevState, { description: contentText }]);
        formRef.current.resetFields();
    }

    const handleContentChange = (itemIdx) => {
        const contentArr = contents;
        const filteredArr = contentArr.filter((item, idx) => idx !== itemIdx);
        setContents(filteredArr);
    }

    const handleChange = (value) => {
        setContentType(value);
        console.log(value);
        getTestimonialContents();
    }

    const handleClear = (e) => {
        e.preventDefault();
        formRef.current.resetFields();
        setUploadedImage(null);
    }

    const handleHeaderScrollSubmit = async (e) => {
        await addContent(contents);
        setContents([]);
    }

    const handleTestimonialSave = async (e) => {
        e.preventDefault();
        const { image, name, city, state, content } = formRef.current.getFieldValue();
        if (!uploadedImage || !name || !city || !state || !content) {
            message.error("Please fill in al fields");
            return;
        }
        let payload;
        const currDate = new Date().toISOString();
        const dateArr = currDate.split('T')[0].split('-');
        const date = `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
        const base64 = await encodeBase64File(uploadedImage);
        payload = {
            city,
            date: date,
            description: content,
            image: base64,
            imageName: uploadedImage.name,
            name,
            state
        }
        if (uploadedImage.type.includes("pdf")) {
            payload.imageType = "pdf";
        } else if (uploadedImage.type.includes("png")) {
            payload.imageType = "png";
        } else if (uploadedImage.type.includes("jpg")) {
            payload.imageType = "jpg";
        } else if (uploadedImage.type.includes("jpeg")) {
            payload.imageType = "jpeg";
        } else {
            message.error("Please upload an image type file");
            return;
        }

        await addTestimonialContent(payload);
        formRef.current.resetFields();
        setUploadedImage(null);
    }

    return (
        <Layouts>
            <div className='w-full'>
                <div className='md:flex items-center justify-between md:mb-8 mb-4'>
                    <h1 className='md:text-2xl text-xl font-bold'>Contents</h1>
                </div>
                <div className='md:w-full w-[21rem] px-5 bg-white py-4 rounded-xl'>
                    <Select className='md:w-[30%] w-full md:h-[3rem] h-[2rem] min-h-[6%] my-4' defaultValue={'Header Scroll Content'} placeholder='Select a type' onChange={handleChange}>
                        <Select.Option value="headerscroll">Header Scroll Content</Select.Option>
                        <Select.Option value="testimonial">Testimonial Content</Select.Option>
                    </Select>
                    {
                        contentType === "headerscroll" ? (
                            <div>
                                <div className='bg-lightBlue flex md:flex-row flex-col md:justify-between md:gap-8 shadow-sm p-5 rounded-lg'>
                                    <div className='w-[50%]'>
                                        <Form ref={contentType === 'headerscroll' ? formRef : null}>
                                            <div className='my-5 md:p-5'>
                                                <div className='flex md:flex-row flex-col gap-3'>
                                                    <p className='md:text-xl w-max font-medium md:w-[50%]'>Header Scroll Content :</p>
                                                    <Form.Item name="contentText" className='w-max md:w-[50%]'>
                                                        <Input className='w-full outline-none border-2 border-borderColor p-2 rounded-lg' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='mx-4 my-6'>
                                            <button
                                                onClick={handleAddedItem}
                                                className='min-w-28 px-4 py-2 rounded-lg mt-4 border-2 hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor'
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                    {contents.length !== 0 && <div className='w-[50%] my-5 p-5'>
                                        <p className='md:w-full w-max md:text-xl text-lg font-medium'>Added Contents :</p>
                                        <ul>
                                            {
                                                contents &&
                                                contents.map((item, idx) => (
                                                    <li className='md:w-full w-max flex justify-between gap-3 items-center px-4 py-2'>
                                                        <GoNorthStar className="text-textGray w-[10%]" />
                                                        <span className='font-normal text-lg w-[80%]'>{item.description.length > 30 ? `${item.description.slice(0, 30)}...` : item.description}</span>
                                                        <MdDelete className='text-dangerColor w-[10%]' onClick={() => handleContentChange(idx)} />
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                        <div className='flex flex-row items-center gap-4 md:mx-4 my-8'>
                                            <button
                                                onClick={handleHeaderScrollSubmit}
                                                className='md:min-w-28 min-w-14 w-[30%] md:w-[50%] md:px-4 px-2 md:py-2 py-1 text-sm md:text-lg rounded-lg md:mt-4 mt-2 border md:border-2 hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor'>
                                                Save
                                            </button>
                                            <button
                                                onClick={() => setContents([])}
                                                className='md:min-w-28 min-w-14 w-[30%] md:w-[50%] md:px-4 px-2 md:py-2 py-1 text-sm md:text-lg rounded-lg md:mt-4 mt-2 border md:border-2   hover:bg-dangerColor border-dangerColor text-dangerColor hover:text-white hover:border-White'
                                            >
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                    }
                                </div>
                                {
                                    headerScrollContents?.length !== 0 &&
                                    <div className='mt-6'>
                                        <div className='md:flex items-center justify-between mb-8'>
                                            <h2 className='md:text-2xl text-lg font-bold'>Previously Added Contents :- </h2>
                                        </div>
                                        <ContentTable fields={['Sl.No', 'Content']} tableFields={['description']} data={headerScrollContents} />
                                    </div>
                                }
                            </div>
                        ) :
                            contentType === 'testimonial' ? (
                                <div>
                                    <div >
                                        <div className='bg-lightBlue shadow-sm p-5 rounded-lg'>
                                            <Form ref={contentType === 'testimonial' ? formRef : null}>
                                                <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                                                    <div className='flex flex-col'>
                                                        <div className='my-2'><label className='md:text-lg text-base font-semibold'>Image</label><span className='text-red-500'>*</span></div>
                                                        <Form.Item name='image'>
                                                            <div className='flex justify-center'>
                                                                {uploadedImage === null ? <div className='flex flex-col justify-center relative border-2 border-borderColor h-48 w-48 rounded-[50%]'>
                                                                    <FaUser className='h-[inherit] w-[inherit] p-8  text-primaryColor' />
                                                                    <input type='file' className='w-[inherit] outline-none ml-[2.75rem] absolute left-8 h-16 opacity-0' onChange={handleImage} />
                                                                </div> :
                                                                    <div>
                                                                        <div className={`border-2 w-64 h-64 p-2 rounded-lg  border-borderColor}`}>
                                                                            {/* <div className='flex justify-end text-3xl cursor-pointer py-1' onClick={() => setUploadedImage(null)}>
                                                                            <IoMdCloseCircle />
                                                                        </div> */}
                                                                            <div className='flex justify-center items-center'>
                                                                                <img src={URL.createObjectURL(uploadedImage)} alt={uploadedImage.name} className='w-56 h-56  object-cover' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex items-center justify-between text-2xl cursor-pointer py-1' >
                                                                            <p className='text-base'>{uploadedImage.name.length > 25 ? `${uploadedImage.name.slice(0, 25)}...` : uploadedImage.name}</p>
                                                                            <MdDelete className='text-dangerColor' onClick={() => setUploadedImage(null)} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Form.Item>

                                                    </div>
                                                    <div>
                                                        <div className='my-3'>
                                                            <div className='my-2'><label className='md:text-lg text-base font-semibold'>Name</label><span className='text-red-500'>*</span></div>
                                                            <Form.Item
                                                                name="name"
                                                            >
                                                                <Input placeholder='Enter name' name='name' className=' md:h-12 h-8 outline-none md:border-2 border p-2 rounded-lg' />
                                                            </Form.Item>
                                                        </div>
                                                        <div className='my-3'>
                                                            <div className='my-2'><label className='md:text-lg text-base font-semibold'>City</label><span className='text-red-500'>*</span></div>
                                                            <Form.Item
                                                                name="city"
                                                            >
                                                                <Input placeholder='Enter City' name='city' className='md:h-12 h-8 outline-none md:border-2 border p-2 rounded-lg' />
                                                            </Form.Item>
                                                        </div>
                                                        <div className='my-3'>
                                                            <div className='my-2'><label className='md:text-lg text-base font-semibold'>State</label><span className='text-red-500'>*</span></div>
                                                            <Form.Item
                                                                name="state"
                                                            >
                                                                <Select
                                                                    className='bg-lightBlue md:border-2 border md:h-12 h-8 rounded-lg'
                                                                    placeholder="Select State">
                                                                    {
                                                                        states?.map((state) => (
                                                                            <Select.Option value={state.stateName}>{state.stateName}</Select.Option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className='my-3'>
                                                            <div className='my-2'><label className='md:text-lg text-base font-semibold'>Feedback</label><span className='text-red-500'>*</span></div>
                                                            <Form.Item
                                                                name="content"
                                                            >
                                                                <TextArea autoSize={{ minRows: 3 }} placeholder='Description' name='city' className=' md:h-12 h-8 outline-none md:border-2 border p-2 rounded-lg' />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                            <div className='flex justify-end items-center gap-4 mx-4 my-6'>
                                                <button
                                                    onClick={handleTestimonialSave}
                                                    className='md:min-w-28 min-w-14 w-[30%] md:w-[10%] md:px-4 px-2 md:py-2 py-1 text-sm md:text-lg rounded-lg md:mt-4 mt-2 border md:border-2 hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor'
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    onClick={handleClear}
                                                    className='md:min-w-28 min-w-14 w-[30%] md:w-[10%] md:px-4 px-2 md:py-2 py-1 text-sm md:text-lg rounded-lg md:mt-4 mt-2 border md:border-2   hover:bg-dangerColor border-dangerColor text-dangerColor hover:text-white hover:border-White'
                                                >
                                                    Clear
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        testimonialContents?.length !== 0 &&
                                        <div className='mt-6'>
                                            <div className='md:flex items-center justify-between mb-8'>
                                                <h2 className='md:text-2xl text-lg font-bold'>Previously Added Testimonials :- </h2>
                                            </div>
                                            <ContentTable fields={['Sl.No', 'Name', 'City', 'State', 'Feedback']} tableFields={['name', 'city', 'state', 'description']} data={testimonialContents} />
                                        </div>
                                    }
                                </div>
                            ) : ''
                    }
                </div>


            </div>
        </Layouts>
    )
}

export default ContentPage