import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IoMdCloseCircle } from "react-icons/io";
import { Modal } from "antd";


function PreviewModal({ selectedItem, handleModal, isModalOpen }) {
  const navigate = useNavigate();
  console.log(selectedItem);
  const param = window.location;


  const handleEditClick = () => {
    const keys = Object.keys(selectedItem);
    if (param.pathname === "/productmaster") {
      navigate('/updateProduct', { state: { product: selectedItem } });
    }
    else if(param.pathname === "/productlist"){
      navigate('/updateproductlist', { state: { product: selectedItem } });
    } 
    else if (param.pathname === '/users') {
      navigate('/updateUser',{ state: { user: selectedItem }});
    }
  };

  const keys = Object.keys(selectedItem);
  console.log(keys);


  // return (
  //   <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999999]'>
  //     {/* <div className='gradient-overlay-modal'></div> */}
  //     <div className='h-[30rem] bg-white p-8 rounded-lg overflow-scroll scrollBarWidth'>
  //       {/* <div className='flex justify-end text-3xl cursor-pointer py-1' onClick={handleModal}>
  //         <IoMdCloseCircle />
  //       </div> */}
  //       {/* <div className='border-t-borderColor py-3 ' style={{ borderTopWidth: '2px' }}>
  //         <h2 className='text-2xl font-bold mb-4'>Preview</h2>
  //       </div> */}
  //       <div className='flex flex-col'>
  //         {
  //           keys.map((item, idx) => {
  //             if (item === "productBenfits") {
  //               return (
  //                 <div className=' w-full flex justify-between'>
  //                   <label className='text-xl'>Benefits</label>
  //                   <div>
  //                     {
  //                       selectedItem[item]?.map((benefit) => (
  //                         <p className='w-[70%] border p-3 m-2 border-borderColor rounded-lg'>{benefit.benfitDetails}</p>
  //                       ))
  //                     }
  //                   </div>
  //                 </div>
  //               )

  //             }
  //             else if (item === "productIngredients") {
  //               return (
  //                 <div className=' w-full flex justify-between pr-20'>
  //                   <label className='text-xl'>Ingredients</label>
  //                   <div className='grid grid-cols-5'>
  //                   {
  //                     selectedItem[item]?.map((ingredient) => (
  //                       <p className='border p-3 m-2 border-borderColor rounded-lg'>{ingredient.ingredientName}</p>
  //                     ))
  //                   }
  //                   </div>
  //                 </div>
  //               )
  //             }
  //             else if (item === "productToUse") {
  //               console.log(selectedItem[item])
  //               return (
  //                 <div className=' w-full flex justify-between'>
  //                   <label className='w-[45%] text-xl'>How To Use</label>
  //                   <div className='grid grid-cols-2'>
  //                   {
  //                     selectedItem[item]?.map((howtouse) => (
  //                       <p className='w-[45%] border p-3 m-2 border-borderColor rounded-lg'>{howtouse.description}</p>
  //                     ))
  //                   }
  //                   </div>
  //                 </div>
  //               )
  //             }
  //             else {
  //               return (
  //                 <div className=' w-full flex items-center'>
  //                   <label className='w-[45%] text-xl'>{item}</label>
  //                   <p className='w-[45%] border p-3 m-2 border-borderColor rounded-lg'>{selectedItem ? selectedItem[item] : ''}</p>
  //                 </div>

  //               )
  //             }
  //           })
  //         }

  //       </div>
  //       <div className='flex justify-end mx-4 my-6'>
  //         <button
  //           onClick={handleEditClick}
  //           className='min-w-28 px-4 py-2 rounded-lg mt-4 border-2 hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor'
  //         >
  //           Edit
  //         </button>
  //         <button
  //           className='min-w-28 px-4 py-2 mx-2 rounded-lg mt-4 border-2 custom-button-shadow hover:text-white text-dangerColor border-dangerColor'
  //           onClick={handleModal}
  //         >
  //           Close
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <Modal
      open={isModalOpen}
      onCancel={handleModal}
      title={"Preview"}
      width={"80%"}
      footer={[]}
    >
      <div className=' bg-white p-8 rounded-lg overflow-scroll scrollBarWidth'>
        {/* <div className='flex justify-end text-3xl cursor-pointer py-1' onClick={handleModal}>
          <IoMdCloseCircle />
        </div> */}
        {/* <div className='border-t-borderColor py-3 ' style={{ borderTopWidth: '2px' }}>
          <h2 className='text-2xl font-bold mb-4'>Preview</h2>
        </div> */}
        <div className='flex flex-col'>
          {
            keys.map((item, idx) => {
              if (item === "productBenfits") {
                return (
                  <div className=' w-full flex items-center'>
                    <label className='text-xl w-[45%]'>Benefits</label>
                    <div className='w-[45%] grid grid-cols-2'>
                      {
                        selectedItem[item]?.map((benefit) => (
                          <p className='border p-3 m-2 border-borderColor rounded-lg'>{benefit.benfitDetails}</p>
                        ))
                      }
                    </div>
                  </div>
                )

              }
              else if (item === "productIngredients") {
                return (
                  <div className=' w-full flex items-center'>
                    <label className='text-xl w-[45%]'>Ingredients</label>
                    <div className='grid grid-cols-4'>
                      {
                        selectedItem[item]?.map((ingredient) => (
                          <p className='border p-3 m-2 border-borderColor rounded-lg'>{ingredient.ingredientName}</p>
                        ))
                      }
                    </div>
                  </div>
                )
              }
              else if (item === "productToUse") {
                console.log(selectedItem[item])
                return (
                  <div className=' w-full flex items-center'>
                    <label className='w-[45%] text-xl'>How To Use</label>
                    <div className='w-[45%] grid grid-cols-2'>
                      {
                        selectedItem[item]?.map((howtouse) => (
                          <p className='border p-3 m-2 border-borderColor rounded-lg'>{howtouse.description}</p>
                        ))
                      }
                    </div>
                  </div>
                )
              }
              else if (item === "productPackageDetails"){
                return (
                  <div className=' w-full flex items-center'>
                    <label className='w-[45%] text-xl'>Package Details</label>
                    <div className='w-[45%] grid grid-cols-2'>
                      {
                        selectedItem[item]?.map((size) => (
                          <p className='border p-3 m-2 border-borderColor rounded-lg'>{size.packageSize}</p>
                        ))
                      }
                    </div>
                  </div>
                )
              }
              else {
                return (
                  <div className=' w-full flex items-center'>
                    <label className='w-[45%] text-xl'>{item}</label>
                    <p className='w-[45%] border p-3 m-2 border-borderColor rounded-lg'>{selectedItem ? selectedItem[item] : ''}</p>
                  </div>

                )
              }
            })
          }

        </div>
        <div className='flex justify-end mx-4 my-6'>
          
          <button
            onClick={handleEditClick}
            className={`${(!keys.includes("Customer") || param.pathname !== 'productAvailability')?'hidden':''} min-w-28 px-4 py-2 rounded-lg mt-4 border-2 hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor`}
          >
            Edit
          </button>
          <button
            className='min-w-28 px-4 py-2 mx-2 rounded-lg mt-4 border-2 custom-button-shadow hover:text-white text-dangerColor border-dangerColor'
            onClick={handleModal}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default PreviewModal;
