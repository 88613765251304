import React, { useEffect, useRef, useState } from 'react';
import Layouts from '../../../Layouts';
import { BsCloudUpload } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';
import { LuCircleDotDashed } from 'react-icons/lu';
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
// import EditModal from './EditModal';
import { DatePicker, Form, InputNumber, Select, message, Input } from 'antd';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { encodeBase64File } from "../../../../utils/encodeBase64"
import { approveInventory, editApprovedInventory, editInventory, getPackageDetails, getProducts, updateInventory } from '../../../../functions/products';
import { Option } from 'antd/es/mentions';
import moment from 'moment';
import dayjs from 'dayjs';
import { secureStorage } from '../../../../functions/isAuth';

function UpdateProductList() {
    const [manufacturedDate, setManufacturedDate] = useState();
    const [expiryDate, setExpriyDate] = useState();
    const [howToUseModal, setHowToUseModal] = useState();
    const [ingredientsModal, setIngredientsModal] = useState();
    const [benefitsModal, setBenefitsModal] = useState();
    const navigate = useNavigate();
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [image, setImage] = useState([]);
    const [productData, setProductData] = useState();
    const [productNames, setProductNames] = useState();
    const [selectedProductSizes, setSelectedProductSizes] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [packages, setPackages] = useState();
    const [selectedPackage, setSelectedPackage] = useState();
    const [unitPrice, setUnitPrice] = useState();
    const [discountAmount, setDiscountAmount] = useState();
    const [finalPrice, setFinalPrice] = useState();
    const [isEditOn, setIsEditOn] = useState(false);
    const [editProductName, setEditProductName] = useState();

    const formRef = useRef();
    const { state } = useLocation();

    useEffect(() => {
        const token = secureStorage.getItem('access_token');
        if (!token) {
            navigate('/');
        }
        else {
            getAllProducts();
            getPackages();
        }
    }, []);



    useEffect(() => {
        if (state?.product !== null) {
            setIsEditOn(true);
        }
    }, [state?.product]);

    const getAllProducts = async () => {
        const response = await getProducts();
        setProductNames(response.data);
        if (state?.product) {
            setProductObject(response.data);
        }
    }

    const setProductObject = (data) => {
        const editProduct = data?.filter((item, idx) => item.code === state?.product.productCode)[0];
        console.log(editProduct);
        setEditProductName(editProduct.name);
    }

    const getPackages = async () => {
        const response = await getPackageDetails();
        setPackages(response.data);
    }

    const handleCancelClick = () => {
        navigate('/productlist');
    }

    const handleSubCategoryChange = (value) => {
        setSubCategory(value);
    }
    const handleUploadDelete1 = (imageToDelete) => {
        let imagesArr = image;
        const images = imagesArr.filter((img) => img.name !== imageToDelete.name);
        if (images) {
            setImage(images)
        }
        else {
            setImage([]);
        }

    };

    const categoryChange = (value) => {
        setCategory(value);
    }

    const handleModal = (type) => {
        if (type === 'ingredients') {
            setIngredientsModal(prevState => !prevState);
            setHowToUseModal(false);
            setBenefitsModal(false);
        }
        else if (type === 'howToUse') {
            setHowToUseModal(prevState => !prevState);
            setIngredientsModal(false);
            setBenefitsModal(false);
        }
        else if (type === 'benefits') {
            setBenefitsModal(prevState => !prevState);
            setHowToUseModal(false);
            setIngredientsModal(false);
        }
    }

    const handleUnitPriceChange = (value) => {
        setUnitPrice(value);
        if (value === null) {
            setDiscountAmount();
            setFinalPrice();
        }
        const values = formRef.current.getFieldValue();
        const keys = Object.keys(values);
        if (keys.includes('discountpercent')) {
            let discountAmt;
            let discountedValue;
            let discountValue = values.discountpercent;
            if (discountValue > 0) {
                discountAmt = Math.floor(value * ((discountValue / 100).toFixed(2)));
                discountedValue = value - (Math.floor(value * ((discountValue / 100).toFixed(2))));
                setDiscountAmount(discountAmt);
                setFinalPrice(discountedValue);
            }
        }

    }

    const handleDiscountChange = (value) => {
        const discountValue = Number(value);
        let discountAmt;
        let discountedValue;
        if (discountValue > 0) {
            let unitprice = unitPrice ? unitPrice : state?.product.unitPrice;
            discountAmt = Math.floor(unitprice * ((discountValue / 100).toFixed(2)));
            discountedValue = unitprice - (Math.floor(unitprice * ((discountValue / 100).toFixed(2))));
            setDiscountAmount(discountAmt);
            setFinalPrice(discountedValue);

        }
        else {
            setDiscountAmount(0);
            setFinalPrice(unitPrice);
        }

    }

    const handleImage = (e) => {
        setImage([...image, e.target.files[0]]);
    }
    const handleSizes = (value) => {
        setSelectedPackage(value);
    }

    const handleManufacturedDate = (value, dateString) => {
        console.log(dateString);
        const manuf_Date = `${dateString} 00:00:00`;
        setManufacturedDate(manuf_Date);
    }

    const handleExpiryDate = (value, dateString) => {
        const exp_Date = `${dateString} 00:00:00`;
        setExpriyDate(exp_Date);
    }

    const handleSubmit = async (e,actionType) => {
        e.preventDefault();
        const currDate = new Date().toISOString();
        const date = currDate.split('T')[0];
        const time = currDate.split('T')[1].slice(0, 8);
        const constructedTime = date + " " + time;
        let payload;
        const { productcode, packagecode, unitprice, discountpercent, quantity, batchcode } = formRef.current.getFieldValue();
        if (state?.product.productCode === "" || state?.product.productCode === undefined || state?.product.productCode === null) {
            if (productcode === "" || productcode === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product.packageCode === "" || state?.product.packageCode === undefined || state?.product.packageCode === null) {
            if (packagecode === "" || packagecode === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product.bachCode === "" || state?.product.bachCode === undefined || state?.product.bachCode === null) {
            if (batchcode === "" || batchcode === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product.unitPrice === "" || state?.product.unitPrice === undefined || state?.product.unitPrice === null) {
            if (unitprice === "" || unitprice === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product.qty === "" || state?.product.qty === undefined || state?.product.qty === null) {
            if (quantity === "" || quantity === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (state?.product.discountPercentage === "" || state?.product.discountPercentage === undefined || state?.product.discountPercentage === null) {
            if (discountpercent === "" || discountpercent === undefined) {
                message.error("Please fill in all fields");
                return;
            }
        }

        if(actionType === 'save'){
            if (state?.product !== null && state?.product !== undefined && state?.product !== "") {
                payload = {
                    bachCode: batchcode ? batchcode : state?.product.bachCode,
                    createdBy: "VAD",
                    createdDate: state?.product.createdDate,
                    discountPercentage: discountpercent ? discountpercent : state?.product.discountPercentage,
                    discountPrice: discountAmount ? discountAmount : state?.product.discountPrice,
                    expireDate: expiryDate,
                    finalPrice: finalPrice ? finalPrice : state?.product.finalPrice,
                    manufacturDate: manufacturedDate,
                    modifiedBy: "VAD",
                    modifiedDate: constructedTime,
                    packageCode: selectedPackage ? selectedPackage : state?.product.packageCode,
                    productCode: selectedProduct ? selectedProduct.code : state?.product.productCode,
                    qty: quantity ? quantity : state?.product.qty,
                    status: state?.product.status?state?.product.status:1,
                    unitPrice: unitprice ? unitprice : state?.product.unitPrice
                }
                await editInventory(payload, state?.product.id);
                navigate('/productlist');
            }
            else {
                payload = {
                    bachCode: batchcode,
                    createdBy: "VAD",
                    createdDate: constructedTime,
                    discountPercentage: discountpercent,
                    discountPrice: discountAmount,
                    expireDate: expiryDate,
                    finalPrice: finalPrice ? finalPrice : unitPrice,
                    manufacturDate: manufacturedDate,
                    modifiedBy: "VAD",
                    modifiedDate: constructedTime,
                    packageCode: selectedPackage,
                    productCode: selectedProduct.code,
                    qty: quantity,
                    status: 1,
                    unitPrice: unitprice
                }
                await updateInventory(payload);
                navigate('/productlist');
            }
        }
        else if(actionType === 'approve'){
            if (state?.product !== null && state?.product !== undefined && state?.product !== "") {
                payload = {
                    bachCode: batchcode ? batchcode : state?.product.bachCode,
                    createdBy: "VAD",
                    createdDate: state?.product.createdDate,
                    discountPercentage: discountpercent ? discountpercent : state?.product.discountPercentage,
                    discountPrice: discountAmount ? discountAmount : state?.product.discountPrice,
                    expireDate: expiryDate,
                    finalPrice: finalPrice ? finalPrice : state?.product.finalPrice,
                    manufacturDate: manufacturedDate,
                    modifiedBy: "VAD",
                    modifiedDate: constructedTime,
                    packageCode: selectedPackage ? selectedPackage : state?.product.packageCode,
                    productCode: selectedProduct ? selectedProduct.code : state?.product.productCode,
                    qty: quantity ? quantity : state?.product.qty,
                    status: 2,
                    unitPrice: unitprice ? unitprice : state?.product.unitPrice
                }
                await editInventory(payload, state?.product.id);
                navigate('/productlist');
            }
            else {
                payload = {
                    bachCode: batchcode,
                    createdBy: "VAD",
                    createdDate: constructedTime,
                    discountPercentage: discountpercent,
                    discountPrice: discountAmount,
                    expireDate: expiryDate,
                    finalPrice: finalPrice ? finalPrice : unitPrice,
                    manufacturDate: manufacturedDate,
                    modifiedBy: "VAD",
                    modifiedDate: constructedTime,
                    packageCode: selectedPackage,
                    productCode: selectedProduct.code,
                    qty: quantity,
                    status: 2,
                    unitPrice: unitprice
                }
                await updateInventory(payload);
                navigate('/productlist');
            }
        }
        
        

    }
    

    const productChange = (value) => {
        const selectedOne = productNames.filter((item) => item.name === value)[0];
        setSelectedProduct(selectedOne);
        console.log("val ",value," selected ",selectedOne);
        setSelectedProductSizes(selectedOne.productPackageDetails);
    }

    const handlePastDates = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    const handleFutureDates = (current) => {
        return current && current > dayjs().endOf('day');
    }


    return (
        <Layouts>
            <div>
                <div className='md:flex items-center justify-between md:mb-8 mb-4'>
                    <h1 className='md:text-2xl text-xl font-bold'>{state?.product ? "Edit" : "New Product"}</h1>
                </div>
                <Form ref={formRef}
                    initialValues={{
                        productcode: state?.product.productCode,
                        packagecode: state?.product.packageCode,
                        batchcode: state?.product.bachCode,
                        unitprice: state?.product.unitPrice,
                        quantity: state?.product.qty,
                        discountpercent: state?.product.discountPercentage,
                        // manufacturedDate:moment(state?.product.manufacturDate.split(" ")[0], "DD/MM/YYYY"),
                        // expiryDate:moment(state?.product.expireDate.split(" ")[0], "DD/MM/YYYY")
                    }}
                >
                    <div className='md:w-full md:flex'>
                        <div className='leftForm bg-white rounded-xl md:w-2/4 md:p-6 p-3 mx-1'>
                            <div className='md:w-[75%] w-full md:my-2 my-0.5'>
                                <div>
                                    <div className='my-2'><label className='md:text-lg text-base font-medium'>Product Name</label><span className='text-dangerColor'>*</span></div>
                                    <Form.Item name="productcode">
                                        <Select
                                            onChange={productChange}
                                            placeholder="Select a Product Name"
                                            className='md:w-full md:h-[2.5rem] h-[2.25rem]'
                                        >
                                            {productNames?.map((item) => (
                                                <Option key={item.id} value={item.name}>{`${item.name} -  ${item.code}`}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='w-full flex md:flex-row flex-col items-center justify-between'>

                                <div className='md:w-[18rem] w-full md:my-2 my-0.5'>
                                    <div>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Package Size</label><span className='text-dangerColor'>*</span></div>
                                        <Form.Item name="packagecode">
                                            <Select
                                                onChange={handleSizes}
                                                placeholder="Select Package sizes"
                                                className='md:w-full md:h-[2.5rem] h-[2.25rem]'
                                            >
                                                {
                                                    selectedProductSizes?.map((size) => (
                                                        <Option key={size.id} value={size.packageSize}>{size.packageSize}</Option>
                                                    ))
                                                }

                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>


                                <div className='md:w-[18rem] w-full md:my-2 my-0.5'>
                                    <div>
                                        <div className='my-2'><label className='md:text-lg text-base font-medium'>Batch Code</label><span className='text-dangerColor'>*</span></div>
                                        <Form.Item name="batchcode">
                                            <InputNumber placeholder='Enter Batch Code' className='w-full outline-none border-2 border-borderColor md:p-2 p-1 rounded-lg' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex md:flex-row flex-col items-center justify-between'>
                                <div className='flex items-center justify-between'>
                                    <div className='w-[18rem] md:my-2 my-0.5'>
                                        <div>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>Unit Price</label><span className='text-dangerColor'>*</span></div>
                                            <Form.Item name="unitprice">
                                                <InputNumber placeholder='Enter Unit Price' className='w-full outline-none border-2 border-borderColor md:p-2 p-1 rounded-lg' onChange={handleUnitPriceChange} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex items-center justify-between'>
                                    <div className='w-[18rem] md:my-2 my-0.5'>
                                        <div>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>Quantity</label><span className='text-dangerColor'>*</span></div>
                                            <Form.Item name="quantity">
                                                <InputNumber placeholder='Enter Quantity' className='w-full outline-none border-2 border-borderColor md:p-2 p-1 rounded-lg' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='w-full flex md:flex-row flex-col items-center justify-between'>
                                <div className='flex items-center justify-between'>
                                    <div className='w-[18rem] my-2'>
                                        <div>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>Discount Percent</label><span className='text-dangerColor'>*</span></div>
                                            <Form.Item name="discountpercent"
                                                rules={[{
                                                    validator: (_, value) => {
                                                        if (value > 100) {
                                                            return Promise.reject(new Error("Maximum discount is 100% only."));
                                                        }
                                                        else {
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                }]}
                                            >
                                                <InputNumber placeholder='Enter Discount Percent' className='w-full outline-none border-2 border-borderColor md:p-2 p-1 rounded-lg' onChange={handleDiscountChange} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <Form.Item name="discountprice">
                                    <div className='flex items-center justify-between'>
                                        <div className='w-[18rem] my-2'>
                                            <div>
                                                <div className='my-2'><label className='md:text-lg text-base font-medium'>Discount Amount</label><span className='text-dangerColor'>*</span></div>
                                                <InputNumber value={discountAmount ? discountAmount : state?.product ? state?.product.discountPrice : ''} placeholder='Discount Amount' className='w-full outline-none border-2 border-borderColor md:p-2 p-1 rounded-lg' />
                                            </div>
                                        </div>
                                    </div>
                                </Form.Item>
                            </div>
                            <Form.Item name="finalprice">
                                <div className='flex items-center justify-between'>
                                    <div className='w-[18rem] my-2'>
                                        <div>
                                            <div className='my-2'><label className='md:text-lg text-base font-medium'>Final Price</label><span className='text-dangerColor'>*</span></div>
                                            <InputNumber value={finalPrice ? finalPrice : state?.product ? state?.product.finalPrice : ''} placeholder='Final Price' className='w-full outline-none border-2 border-borderColor md:p-2 p-1 rounded-lg' />
                                        </div>
                                    </div>
                                </div>
                            </Form.Item>

                        </div>
                        <div className='rightForm relative md:mt-0 mt-6 bg-white rounded-xl md:w-2/4 h-screen p-6 mx-1'>
                            <div className='w-full flex md:flex-row flex-col md:items-center md:justify-between py-2'>
                                <div className='md:w-2/5'>
                                    <div className='my-2'><label className='md:text-lg text-base font-medium'>Manufactured Date</label><span className='text-dangerColor'>*</span></div>
                                    <Form.Item name="manufacturedDate">
                                        <DatePicker
                                            placeholder="Date of manufacturing"
                                            format="YYYY-MM-DD"
                                            className="w-full md:h-[2.5rem] h-[2rem]"
                                            disabledDate={handleFutureDates}
                                            onChange={handleManufacturedDate}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='md:w-2/5'>
                                    <div className='my-2'><label className='md:text-lg text-base font-medium'>Expiry Date</label><span className='text-dangerColor'>*</span></div>
                                    <Form.Item name="expiryDate">
                                        <DatePicker
                                            placeholder="Date of expiry"
                                            format="YYYY-MM-DD"
                                            className="w-full md:h-[2.5rem] h-[2rem]"
                                            disabledDate={handlePastDates}
                                            onChange={handleExpiryDate}
                                        />
                                    </Form.Item>
                                </div>

                            </div>
                            <div className='w-full absolute bottom-5 px-3'>
                                <div className='w-[inherit] flex flex-col items-end gap-3 px-5'>
                                    <button className='w-[6rem] md:w-2/5 md:p-3 p-1 bg-primaryColor text-white rounded-lg md:font-semibold font-medium' onClick={(e) =>handleSubmit(e,'save')}>{state?.product? 'Update' : 'Save'}</button>
                                    <button disabled={state?.product.status === 2} className={`w-[6rem] md:w-2/5 md:p-3 p-1 bg-successColor text-white rounded-lg font-semibold ${state?.product.status === 2?'opacity-50':''}`} onClick={(e) => handleSubmit(e,'approve')} >Approve</button>
                                    <button className='w-[6rem] md:w-2/5 md:p-3 p-1 border border-dangerColor text-dangerColor rounded-lg font-semibold custom-button-shadow hover:text-white' onClick={handleCancelClick}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>

            {/* <>
                {howToUseModal && <EditModal selectedItem={howToUse} handleModal={handleModal} type={"howToUse"} />}
            </>
            <>
                {benefitsModal && <EditModal selectedItem={benefits} handleModal={handleModal} type={"benefits"} />}
            </>
            <>
                {ingredientsModal && <EditModal selectedItem={ingredients} handleModal={handleModal} type={"ingredients"} />}
            </> */}


        </Layouts>
    )
}

export default UpdateProductList;
