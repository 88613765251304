import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import adminLogo from '../../../Assets/Images/Icons/logo.png';
import { getToken, secureStorage } from '../../../functions/isAuth';
import { Form, Input, message } from 'antd';

const Login = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = secureStorage.getItem('access_token');
    if (token) {
      navigate('/productmaster');
    }
}, []);

  const handleLogin = async () => {
    const { email, password } = formRef.current.getFieldValue();
    if (!email || !password) {
      message.error('Please provide email and password!');
      return;
    }

    const userData = {
      userId: email,
      password,
    };

    const response = await getToken(userData);
    console.log(response);
    if (response.data.status === 1) {
      navigate('/productmaster');
    }
  };

  return (
    <div className="relative flex md:justify-evenly justify-between items-center bg-darkBlue md:h-screen h-[max-content] p-8 overflow-y-scroll">
      <div className='gradient-overlay hidden md:block'></div>
      <div className="hidden md:flex md:items-center md:justify-center mb-8">
        <img src={adminLogo} alt="logo" className="w-24 h-24 mr-4" />
        <div>
          <h1 className="text-3xl font-semibold">Admin Panel</h1>
          <p className="text-textGray text-lg">Manage your products with ease</p>
        </div>
      </div>
      <div className="w-full relative max-w-md bg-white rounded-lg p-8 shadow-lg">
        <div className="md:hidden flex flex-col items-center justify-center mb-8">
          <img src={adminLogo} alt="logo" className="w-12 h-12 mr-4" />
          <div className='flex flex-col items-center'>
            <h1 className="md:text-3xl text-2xl  font-medium md:font-semibold">Admin Panel</h1>
            <p className="text-textGray text-base">Manage your products with ease</p>
          </div>
        </div>
        <div className="my-6">
          <h2 className="md:text-3xl text-xl font-medium md:font-semibold">Login to account</h2>
          <span className="text-textGray text-xs md:text-base">Enter your email & password to login</span>
        </div>
        <Form ref={formRef} className="w-full">
          <div className="my-6">
            <label className="md:text-lg text-base  font-medium">Email address</label>
            <span className="text-red-500">*</span>
            <Form.Item
              name="email"
              rules={[
                { type: 'email', message: 'Please enter a valid email address!' },
              ]}
            >
              <Input type="email" placeholder="Email address" className="w-full mt-1 md:mt-2 outline-none border rounded-lg border-gray-300 p-2 md:p-3" />
            </Form.Item>
          </div>
          <div className="my-6">
            <label className="md:text-lg text-base  font-medium">Password</label>
            <span className="text-red-500">*</span>
            <Form.Item
              name="password"
              rules={[
                { max: 12, min: 6, message: 'Password size must be 6-12 characters' },
              ]}
            >
              <Input.Password placeholder="Password" className="w-full mt-1 md:mt-2 outline-none border rounded-lg border-gray-300 p-2 md:p-3" />
            </Form.Item>
          </div>
          <div className="my-4 text-right">
            <Link to={'/forgotPassword'} className="text-textGray text-sm md:text-base">forget password?</Link>
          </div>
        </Form>
        <button className="w-full p-1.5 md:p-3 bg-primaryColor text-white rounded-lg font-medium md:font-semibold" onClick={handleLogin}>Login</button>
        {/* <div className="text-primaryColor my-4 flex justify-center">
          <Link to={'/register'}>Haven't registered yet ..?</Link>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
