import React, { useEffect, useState } from 'react'
import Layouts from '../../Layouts'
import { IoSearchOutline } from 'react-icons/io5'
import Table from './UserTable.jsx'
import { userData } from '../../../data/UserData.js'
import { useNavigate } from 'react-router-dom'
import Modal from '../../Shared/Modal.jsx'
import { MdOutlineTipsAndUpdates } from 'react-icons/md';
import ShimmerEffect from '../../Shared/ShimmerEffect.jsx'
import { User } from '../../../functions/user.js'
import { secureStorage } from '../../../functions/isAuth.js'

function Users() {
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(userData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const arr = new Array(5).fill(0);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState();

    useEffect(() => {
        const token = secureStorage.getItem('access_token');
        if (!token) {
            navigate('/');
        }
        else {
            getUser();
        }
    }, []);


    const getUser = async () => {
        const response = await User();
        setUser(response.data);
        if(response.data){
            setIsLoading(false);
        }
        console.log(response.data);
    }

    const handleClick = () => {
        navigate('/updateUser');
    }

    const handleModal = (actionType, selectedObj) => {
        setIsModalOpen(prevState => !prevState);
        if (actionType === 'view') {
            setSelectedItem(selectedObj)
        }
    }



    return (
        <>
            <Layouts>
                <div className='md:w-full w-screen '>
                    <div className='md:flex items-center justify-between md:mb-8 mb-4'>
                        <h1 className='md:text-2xl text-xl font-bold'>All Users</h1>
                    </div>
                    <div className='flex'>
                        <div className='bg-white rounded-xl md:w-full flex-1 md:p-8 p-4'>
                            <div className='flex gap-2 items-center text-textGray md:text-sm text-xs'><MdOutlineTipsAndUpdates className='md:text-base text-sm text-primaryColor' />Tip : Enhance your search experience by using the email or username filter.</div>
                            <div>
                                <div className='w-full flex justify-between items-center py-6'>
                                    <div className='md:w-full w-[70%]  flex items-center'>
                                        <div className='md:w-80 w-[inherit] flex items-center justify-between border border-gray-400 rounded-lg md:py-2 py-1 md:px-5 px-3'>
                                            <input type='text' placeholder='Search here...' className='md:w-[95%] w-[inherit] outline-none md:placeholder:text-base placeholder:text-sm' />
                                            <IoSearchOutline className="md:text-xl text-sm" />
                                        </div>
                                    </div>
                                    <button className='md:py-[7px] py-[5px] md:w-52 w-[30%] md:text-base text-sm font-semibold rounded-lg border border-primaryColor text-primaryColor' onClick={handleClick}> <span className='font-normal md:text-lg text-sm md:pr-3 pr-1 '>+</span> Add User</button>
                                </div>
                            </div>
                            {
                                isLoading && !user ?
                                    (<div className='flex flex-col gap-3 items-center'>
                                        {
                                            arr.map((idx) => (<ShimmerEffect key={idx} />))
                                        }
                                    </div>) :
                                    (
                                        <Table data={user} handleModal={handleModal} user={userData.filtered} />
                                    )

                            }
                        </div>
                    </div>
                </div>
            </Layouts>
            {isModalOpen && <Modal selectedItem={selectedItem} isModalOpen={isModalOpen} handleModal={handleModal} />}

        </>
    )
}

export default Users;
