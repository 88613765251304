import React, { useEffect, useState } from 'react'
import { BsCloudUpload } from 'react-icons/bs';
import { encodeBase64File } from '../../../../utils/encodeBase64';
import { Modal, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { addProductImages, debouncedAddProductImages, debouncedEditProductImages, getProductImages } from '../../../../functions/products';
import { IoMdCloseCircle } from 'react-icons/io';
import { BiEditAlt } from 'react-icons/bi';
import { secureStorage } from '../../../../functions/isAuth';

function AddImages({ product, productsLength, handleCancel }) {
  const [imagesCount, setImagesCount] = useState(0);
  // const [image, setImage] = useState();
  const [images, setImages] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const [productImages, setProductImages] = useState([]);
  const navigate = useNavigate();
  const [isEditImage, setIsEditImage] = useState(false);
  const [selectedEditImage, setSelectedEditImage] = useState();
  const [isAddMoreImages, setIsAddMoreImages] = useState(false);
  // console.log(product)
  console.log("selected ", selectedItem)

  useEffect(() => {
    const token = secureStorage.getItem('access_token');
    if (!token) {
      navigate('/');
    }
    else{
    fetchProductImagesList(product.code);
    }
}, []);

  useEffect(() => {
    console.log(productImages);
  }, [productImages])

  const fetchProductImagesList = async (code) => {
    try {
      const response = await getProductImages(code);
      if (response && response?.length !== 0) {
        setProductImages(response.data);
        setImagesCount(response.data.length);
      }
    }
    catch (e) {
      console.log(e);
    }

  }

  const handleAddMoreImages = () => {
    setIsAddMoreImages(true);
  }


  const handleImage = async (e) => {
    setImagesCount(prevState => prevState <= 5 ? prevState + 1 : prevState);
    const imageFile = e.target.files[0];
    setSelectedItemIndex(-1);

    // setImage(imageFile);
    if (imagesCount <= 5) {
      const imageFile = e.target.files[0];
      if (imageFile !== null && imageFile !== undefined && imageFile !== "") {
        const base64 = await encodeBase64File(imageFile);
        setImages(prevImages => [...prevImages, { file: imageFile, base64 }]);
      }

    }

  }

  const handleClose = (itemIndex) => {
    console.log(isEditImage);
    if (isEditImage) {
      console.log("hiiii");
      setImages([]);
      setSelectedItem('');
    }
    else {
      const filteredImages = images.filter((item, idx) => idx !== itemIndex);
      setImages(filteredImages);
      setImagesCount(prevState => prevState <= 5 ? prevState - 1 : prevState);
    }
  }

  const handleSelected = (selectedImage, itemIndex) => {
    setSelectedItemIndex(itemIndex);
    setSelectedItem(selectedImage);
  }

  const handleImageUpload = async () => {
    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    if (selectedItem?.file.name !== images[0].file.name) {
      message.error("Plese select an Image before clicking upload");
      return;
    }


    let payload = {
      createdBy: "VAD",
      createdDate: constructedTime,
      description: product.name,
      image: selectedItem.base64,
      imageName: selectedItem.file.name,
      modifiedBy: "VAD",
      modifiedDate: constructedTime,
      productCode: product.code,
      status: 1
    }

    if (selectedItem.file.type.includes("pdf")) {
      payload.imageType = "pdf";
    } else if (selectedItem.file.type.includes("png")) {
      payload.imageType = "png";
    } else if (selectedItem.file.type.includes("jpg")) {
      payload.imageType = "jpg";
    } else if (selectedItem.file.type.includes("jpeg")) {
      payload.imageType = "jpeg";
    } else {
      message.error("Please upload an image");
      return;
    }

    if (isEditImage) {
      console.log(selectedEditImage.id, payload)
      await debouncedEditProductImages(payload, selectedEditImage.id);
      setTimeout(() => {
        setIsEditImage(false);
        handleCancel();
      }, 3000)
      setImages(null);

    }
    else {
      await debouncedAddProductImages(payload);
      const filteredImages = images?.filter((item, idx) => item.file.name !== selectedItem.file.name);
      if (filteredImages?.length === 0) {
        setTimeout(() => {
          setIsAddMoreImages(false);
          handleCancel();
        }, 3000);
      }
      setImages(filteredImages);
    }


  }

  const handleImageEdit = (item) => {
    setIsEditImage(true);
    setSelectedEditImage(item);
  }



  return (
    <>
      <div>
        {
          (productImages && productImages?.length === 0) ?
            <div>
              <p className='text-2xl font-bold mb-4'>Add More Images :</p>
              <span className='text-gray-400'>You can upload <span className='text-dangerColor'>{5 - imagesCount}</span> more images.</span>
              <p className='text-dangerColor'>Image Size must be less than or eqaul to 1 MB.</p>
              <div>
                <div className='w-full flex items-center gap-5 overflow-x-scroll scrollBarWidth'>
                  <div className='my-2 flex flex-col'>
                    <div className='flex flex-col justify-center relative border-2 border-borderColor p-2 h-64 w-64 rounded-lg'>
                      <BsCloudUpload className='text-[3.5rem] relative left-[6rem]  text-primaryColor' />
                      <input type='file' disabled={imagesCount === 5} className='w-[inherit] outline-none ml-[2.75rem] absolute left-8 h-16 opacity-0' onChange={handleImage} />
                    </div>

                  </div>
                  <div className='flex gap-3'>
                    {images && images?.map((image, index) => (
                      <>
                        <div className={`border-2 w-64 h-64 p-2 rounded-lg ${selectedItemIndex === index ? 'border-primaryColor' : 'border-borderColor'}`} onClick={() => handleSelected(image, index)}>
                          <div className='flex justify-end text-3xl cursor-pointer py-1' onClick={() => handleClose(index)}>
                            <IoMdCloseCircle />
                          </div>
                          <div className='flex justify-center items-center'>
                            <img key={index} src={URL.createObjectURL(image.file)} alt={image.file.name} className='w-[80%] h-[80%] object-cover' />
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>

                <div>
                  <div className='flex flex-col mx-4 my-6'>
                    <button
                      onClick={handleImageUpload}
                      className='min-w-28 w-[30%] px-4 py-2 rounded-lg mt-4 border-2 hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor'
                    >
                      Upload
                    </button>
                    {/* <button
                className='min-w-28 w-[70%] px-4 py-2 mx-2 rounded-lg mt-4 border-2 custom-button-shadow hover:text-white text-dangerColor border-dangerColor'
                onClick={handleCancel}
              >
                Close
              </button> */}
                  </div>
                </div>
              </div>

            </div> :
            (isEditImage && productImages?.length !== 0) ?
              <div>
                <p className='text-2xl font-bold mb-4'>Edit Image :</p>
                <p className='text-dangerColor'>Image Size must be less than or eqaul to 1 MB.</p>
                <div>
                  <div className='w-full flex items-center gap-5 overflow-x-scroll scrollBarWidth'>
                    <div className='my-2 flex flex-col'>
                      <div className='flex flex-col justify-center relative border-2 border-borderColor p-2 h-64 w-64 rounded-lg'>
                        <BsCloudUpload className='text-[3.5rem] relative left-[6rem]  text-primaryColor' />
                        <input type='file' disabled={images?.length === 1} className='w-[inherit] outline-none ml-[2.75rem] absolute left-8 h-16 opacity-0' onChange={handleImage} />
                      </div>

                    </div>
                    <div className='flex gap-3'>
                      {images && images?.map((image, index) => (
                        <>
                          <div className={`border-2 w-64 h-64 p-2 rounded-lg ${selectedItemIndex === index ? 'border-primaryColor' : 'border-borderColor'}`} onClick={() => handleSelected(image, index)}>
                            <div className='flex justify-end text-3xl cursor-pointer py-1' onClick={() => handleClose(index)}>
                              <IoMdCloseCircle />
                            </div>
                            <div className='flex justify-center items-center'>
                              <img key={index} src={URL.createObjectURL(image.file)} alt={image.file.name} className='w-[80%] h-[80%] object-cover' />
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div className='flex flex-col mx-4 my-6'>
                      <button
                        onClick={handleImageUpload}
                        className='min-w-28 w-[30%] px-4 py-2 rounded-lg mt-4 border-2 hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor'
                      >
                        Upload
                      </button>
                      {/* <button
              className='min-w-28 w-[70%] px-4 py-2 mx-2 rounded-lg mt-4 border-2 custom-button-shadow hover:text-white text-dangerColor border-dangerColor'
              onClick={handleCancel}
            >
              Close
            </button> */}
                    </div>
                  </div>
                </div>

              </div>
              :
              (isAddMoreImages && productImages.length !== 0) ?
                <div>
                  <p className='text-2xl font-bold mb-4'>Add Images :</p>
                  <span className='text-gray-400'>You can upload <span className='text-dangerColor'>{5 - imagesCount}</span> more images.</span>
                  <p className='text-dangerColor'>Image Size must be less than or eqaul to 1 MB.</p>
                  <div>
                    <div className='w-full flex items-center gap-5 overflow-x-scroll scrollBarWidth'>
                      <div className='my-2 flex flex-col'>
                        <div className='flex flex-col justify-center relative border-2 border-borderColor p-2 h-64 w-64 rounded-lg'>
                          <BsCloudUpload className='text-[3.5rem] relative left-[6rem]  text-primaryColor' />
                          <input type='file' disabled={imagesCount === 5} className='w-[inherit] outline-none ml-[2.75rem] absolute left-8 h-16 opacity-0' onChange={handleImage} />
                        </div>

                      </div>
                      <div className='flex gap-3'>
                        {images && images?.map((image, index) => (
                          <>
                            <div className={`border-2 w-64 h-64 p-2 rounded-lg ${selectedItemIndex === index ? 'border-primaryColor' : 'border-borderColor'}`} onClick={() => handleSelected(image, index)}>
                              <div className='flex justify-end text-3xl cursor-pointer py-1' onClick={() => handleClose(index)}>
                                <IoMdCloseCircle />
                              </div>
                              <div className='flex justify-center items-center'>
                                <img key={index} src={URL.createObjectURL(image.file)} alt={image.file.name} className='w-[80%] h-[80%] object-cover' />
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>

                    <div>
                      <div className='flex flex-col mx-4 my-6'>
                        <button
                          onClick={handleImageUpload}
                          className='min-w-28 w-[30%] px-4 py-2 rounded-lg mt-4 border-2 hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor'
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                : <div className='min-h-[50%]'>
                  <p className='text-2xl font-bold mb-4'>Added Images :</p>
                  {/* <div className='flex items-center gap-3'> */}
                  <span className='text-gray-400'>You can upload <span className='text-dangerColor'>{5 - productImages?.length}</span> more image(s).</span>
                  {/* {productImages?.length < 5 ? <button className='min-w-28 w-[30%] px-2 py-1 rounded-lg mt-4 border-2 hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor' onClick={handleAddMoreImages}>Add</button> : ''} */}
                  {/* </div> */}
                  <ul className='my-4'>
                    {
                      productImages && productImages?.map((image, idx) => (
                        <li className='p-2 my-3 flex items-center' key={image.id}><span className='pr-4'>{idx + 1}.</span><p className='flex items-center gap-4'><span className='pl-4'>{image.imageName}</span><BiEditAlt className='text-successColor text-lg' onClick={() => handleImageEdit(image)} /></p></li>
                      ))
                    }
                  </ul>
                  <div className='flex flex-col mx-4 my-6'>
                    <button
                      onClick={handleAddMoreImages}
                      disabled={productImages?.length === 5}
                      className='min-w-28 w-[30%] px-4 py-2 rounded-lg mt-4 border-2 hover:bg-primaryColor hover:text-white border-primaryColor text-primaryColor'
                    >
                      {`Add ${productImages?.length === 5 ? '' : productImages?.length === 5 ? '' : (5 - productImages?.length)} Image(s)`}
                    </button>
                  </div>
                </div>
        }
      </div>
    </>
  );
}

export default AddImages;