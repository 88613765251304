import React, { useEffect, useState } from 'react';
import Layouts from '../../../Layouts';
import { IoSearchOutline } from 'react-icons/io5';
import { productAvailabilityData } from '../../../../data/productAvailability';
import Table from './ProductAvailabilityTable';
import { getApprovedProductQty } from '../../../../functions/products';
import Modal from '../../../Shared/Modal';
import ShimmerEffect from '../../../Shared/ShimmerEffect';
import { useNavigate } from 'react-router-dom';
import { secureStorage } from '../../../../functions/isAuth';

function ProductAvailability() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState();
    const navigate = useNavigate();
    const arr = new Array(5).fill(0);



    useEffect(() => {
        const token = secureStorage.getItem('access_token');
        if (!token) {
            navigate('/');
        }
        else {
            getProductQtyData();
        }
    }, []);

    const getProductQtyData = async () => {
        const response = await getApprovedProductQty();
        setProducts(response.data);
        if (response.data) {
            setIsLoading(false);
        }
    }
    const handleUpdate = () => {
        navigate('/updateproductlist');
    }



    const handleModal = (actionType, selectedObj) => {
        console.log("hiiii")
        setIsModalOpen(prevState => !prevState);
        if (actionType === 'view') {
            setSelectedItem(selectedObj)
        }
    }

    return (
        <>
            <Layouts>
                <div>
                    <div className='md:flex items-center justify-between md:mb-8 mb-4'>
                        <h1 className='md:text-2xl text-xl font-bold'>Product Availability</h1>
                    </div>
                    <div className='flex'>
                        <div className={`bg-white rounded-xl p-5 ${products ? 'h-max' : 'h-screen'} md:w-full flex-1`}>
                            <div>
                                <div className='flex justify-between items-center py-6'>
                                    <div className='flex gap-3 items-center w-full'>
                                        <div className='md:w-[30%] w-[75%] flex items-center justify-between border-borderColor border rounded-lg md:py-2 py-1 md:px-5 px-3'>
                                            <input type='text' placeholder='Search here...' className='outline-none' />
                                            <IoSearchOutline className="md:text-xl text-lg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                isLoading && !products ?
                                    (<div className='flex flex-col gap-3 items-center'>
                                        {
                                            arr.map((idx) => (<ShimmerEffect key={idx} />))
                                        }
                                    </div>) :
                                    (<Table data={products} handleModal={handleModal} />)


                            }

                        </div>
                    </div>
                </div>
            </Layouts>
            {isModalOpen && <Modal selectedItem={selectedItem} isModalOpen={isModalOpen} handleModal={handleModal} />}
        </>

    )
}

export default ProductAvailability;